// eslint-disable-next-line
export default function() {
    let today = new Date();
    // let today = new Date('2022-09-22');
    // let today = new Date('2022-09-10');
    // let today = new Date('2023-05-01');

    let season = today.getFullYear();
    let month = today.getMonth() + 1;

    if(month < 10) {
        month = "0"+month;
    }

    let day = today.getDate();

    if(day < 10) {
        day = "0"+day;
    }

    const todayString = `${season}-${month}-${day}`;

    let selectboxSeason = season;

    if( month < 5) {
        selectboxSeason = selectboxSeason-1;
    }

    let fromDate = new Date(todayString);
    fromDate.setDate(fromDate.getDate()-7);

    let fromDateString = dateSplitFunc(fromDate);


    return {
        selectboxSeason, 
        season, month, day, todayString,
        fromDate: fromDateString
    }
}

function dateSplitFunc(date) {
    let season = date.getFullYear();
    let month = date.getMonth()+1;
    if(month < 10) {
        month = "0"+month;
    }

    let day = date.getDate();
    if(day < 10) {
        day = "0"+day;
    }

    return `${season}-${month}-${day}`;
}