import React from 'react';
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import SearchBar from "../components/searchBar/SearchBar";
import '../assets/css/main.css';

function MainPage(props) {
    return (
        <>
            <Header serviceList={props.serviceList}/>
            <div id="mainContainer">
                <div>
                    <div id="mainTitle">
                        <div>H.I.T.S.</div>
                        <div>선수정보 통합검색</div>
                    </div>
                    <div className="searchBox">
                        <SearchBar page={"main"}/>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default MainPage;