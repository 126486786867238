import React from 'react';
import _ from 'lodash';
import Chart from "react-apexcharts";

function PieChartBattedBallType({record}){
    // console.log(record);

    let groundBallRate = {
        name: "땅볼",
        value: record ?  record.groundBallRate * 1 : null
    }
    let flyRate = {
        name: "뜬공",
        value: record ? record.flyRate * 1 : null
    }
    let lineDriveRate = {
        name: "라이너",
        value: record ? record.lineDriveRate * 1 : null
    }
    let popUpRate = {
        name: "팝업",
        value: record ? record.popUpRate * 1 : null
    }

    let battedBallArr = [];
    if(groundBallRate.value !== null && groundBallRate.value !== 0){
        battedBallArr.push(groundBallRate);
    }
    if(flyRate.value !== null && flyRate.value !== 0){
        battedBallArr.push(flyRate);
    }
    if(lineDriveRate.value !== null && lineDriveRate.value !== 0){
        battedBallArr.push(lineDriveRate);
    }
    if(popUpRate.value !== null && popUpRate.value !== 0){
        battedBallArr.push(popUpRate);
    }

    // console.log(battedBallArr);

    let sortingBattedBallArr = [];
    if(battedBallArr.length > 0){
        sortingBattedBallArr = _.sortBy(battedBallArr, 'value').reverse();
    }

    // console.log(sortingBattedBallArr);

    let labels = [];
    let value = [];
    if(sortingBattedBallArr.length > 0){
        _.forEach(sortingBattedBallArr, function(item){
            // console.log(item);
            labels.push(item.name);
            value.push(item.value);
        });
    }
    // console.log(labels);
    // console.log(value);

    let chartData = {
        series: value,
        options: {
            chart: {
                type: 'pie',
                toolbar: {
                    show: true,
                    export: {
                        csv: {
                            filename: '타구유형',
                            headerCategory: '타구유형',
                            headerValue: '백분율'
                        },
                        svg: {
                            filename: '백분율'
                        },
                        png: {
                            filename: '백분율'
                        }
                    }
                }
            },
            labels: labels,
            colors: ['#416ab2', '#219f0c', '#ffc20b', '#e04e54'],
            responsive: [{
                breakpoint: 480
            }],
            legend: {
                position: 'bottom'
            },
            dataLabels: {
                style: {
                    fontSize: '15px',
                    fontWeight: 'bold',
                }
            },
            tooltip: {
                custom: function({series, seriesIndex}) {
                    let htmlStr = '';
                    let value = Math.floor(series[seriesIndex] * 10) / 10;

                    htmlStr += '<div class="pieChartTootip">';
                    htmlStr += '<span>';
                    htmlStr += labels[seriesIndex] + ": " + value + "%";
                    htmlStr += '</span>';
                    htmlStr += '</div>';

                    return htmlStr;
                }
            }
        }
    };

    return (
        <>
        {
            sortingBattedBallArr.length > 0 ?
            <Chart options={chartData.options} series={chartData.series} type="pie"/> :
            <div className="noDataWrap">No Data</div>
        }
        </>
    )
}

export default PieChartBattedBallType;