import React from 'react';
import Logo from '../../assets/img/logo_hw.png';
import SubMenu from "./sub/SubMenu";
import Container from 'react-bootstrap/Container';

function Header(props) {
    function logoClickHandler(){
        window.location.href = "/";
    }

    return (
        <>
            <div className='header'>
                <Container>
                    <div id="pageLogoImage">
                        <img src={Logo} alt="#" onClick={logoClickHandler} style={{cursor: 'pointer'}}/>
                    </div>
                </Container>
            </div>
            <SubMenu serviceList={props.serviceList}/>
        </>
    )
}

export default Header;