import React from 'react';

function Footer() {
    return (
        <>
            <div className='footer'>
                <div>
                    <span>상호명 : 하이볼(주)</span>
                    <span>대표 : 김재승</span>
                    <span>주소 : 경상남도 창원시 의창구 용동로57번길 18, 901호(사림동, 시그니처M)</span>
                    <span className="baknone">우편번호 : 51139</span>
                    <span>대표전화 : 055-289-0351</span>
                    <span>FAX : 055-289-0350</span>
                </div>
                <div>
                    <span>COPYRIGHT ⓒ 2023 by Hiball. ALL RIGHTS RESERVED.</span>
                </div>
            </div>
        </>
    )
}

export default Footer;