import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import axios from 'axios';
import _ from 'lodash';
import { useCookies } from 'react-cookie';
import SearchList from '../components/searchBar/sub/SearchList';
import dateSetting from '../utils/dateSetting';
import '../assets/css/playerlist.css';

function PlayerListPage(props) {
    let [playerArr, setPlayerArr] = useState([]);
    const [cookies] = useCookies(['hiball_token']);

    const { searchColumn, searchStr } = useParams();

    useEffect(() => {
        let dateObj = dateSetting();
        let params = {
            recordType: 'profileService',
            recordName: 'playerAllProfile',
            authToken: JSON.stringify(cookies['hiball_token']),
            seasons: dateObj['season']
        }

        if(searchColumn === "career"){
            params['careerSummary'] = searchStr;
        } else if(searchColumn === "draft"){
            params['firstJoinOrder'] = searchStr;
        } else if(searchColumn === "join"){
            params['firstJoinYear'] = searchStr;
            params['groupingColumn'] = "pro";
        }

        let searchProfile = axios({
            url: `${process.env.REACT_APP_API_URL}`, params
        });

        Promise.all([
            searchProfile
        ]).then(value => {
            let profiles = value[0]['data']['result'];

            if(searchStr === "NC"){
                profiles = _.filter(profiles, (item) => item.scoutTeamTypeCodeId !== "9703");
            }

            // console.log(profiles);

            let sortedProfiles = _.sortBy(profiles, ['membersName']);

            // console.log(sortedProfiles);

            setPlayerArr(sortedProfiles);
        })
    },[cookies, searchColumn, searchStr]);

    return (
        <>
            <Header serviceList={props.serviceList}/>
            <div id="playerListContainer">
                <div>
                    <div id="playerListTitle">
                        {
                        searchColumn === "draft" &&
                        "신인드래프트 " + searchStr
                        }
                        {
                        searchColumn === "join" &&
                        searchStr + "년 입단"
                        }
                        {
                        (searchColumn !== "draft" && searchColumn !== "join") &&
                        searchStr
                        }
                    </div>
                    <div id="playerListWrap" className={playerArr.length > 4 ? "overflowItem" : ""}>
                        { 
                        playerArr.length !== 0 && 
                        <SearchList data={playerArr} />
                        }
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default PlayerListPage;