import React, { useState, useEffect } from 'react';
import { Tooltip } from 'react-tooltip'

function StatBarGraph({record, year, stat, playerType}) {
    let [ transitionFlag, setTransitionFlag ] = useState(false);

    let statKey = stat;
    if(statKey === "in_zone_contact" || statKey === "so" || statKey === "bb"){
        statKey += "_pct";
    }
    let value = record[year][statKey];

    let rank = stat + "_rank_pct";
    if(stat === "exit_speed_avg"){
        rank = "exit_speed_rank_pct";
    }
    let rankPercent = record[year] ? (100 - Math.floor(record[year][rank] * 100)) : 0;
    if(rank === "so_rank_pct") {
        rankPercent = 100 - rankPercent;
    }
    const fillWidth = {width: rankPercent + "%"};

    let avgkey = stat;
    if(avgkey === "in_zone_contact"){
        avgkey = "zContactRate";
    }
    if(avgkey === "so"){
        avgkey = "kRate";
    }
    if(avgkey === "bb"){
        avgkey = "bbRate";
    }

    let flag = (rankPercent >= 50) ? true : false;

    useEffect(()=>{
        setTimeout(()=>{ setTransitionFlag(true) }, 100);
    },[]);

    return (
        <>
            {
            (playerType === 'batter' && stat === 'so') || (playerType === 'pitcher' && (stat === 'avg' || stat === 'ops' || stat === 'exit_speed_avg' || stat === 'bb')) ?
            <div className={"recentRecordItemBar" + (flag ? " better" : " worse")} data-tooltip-id="rankTooltip" data-tooltip-content={"상위 " + (rankPercent) + "%"}>
                <div className="value">{value}</div>
                <div className="fill" style={transitionFlag ? fillWidth : {width: 0}}></div>
            </div> :
            <div className={"recentRecordItemBar" + (flag ? " better" : " worse")} data-tooltip-id="rankTooltip" data-tooltip-content={"상위 " + (100 - rankPercent) + "%"}>
                <div className="value">{value}</div>
                <div className="fill" style={transitionFlag ? fillWidth : {width: 0}}></div>
            </div>
            }
            <Tooltip id="rankTooltip"/>
        </>
    )
}

export default StatBarGraph;