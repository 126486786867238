import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainPage from './pages/MainPage';
import SummaryPage from './pages/SummaryPage';
import PlayerListPage from './pages/PlayerListPage';
import NotFoundPage from './pages/NotFoundPage';
import AuthPage from './pages/AuthPage';

function App() {
  const [serviceList, setServiceList] = useState([]);

  return (
    <Router>
      <Routes>
        {
          <Route element={<AuthPage setServiceList={setServiceList}/>} >
            <Route path="/" element={<MainPage serviceList={serviceList}/>}></Route>
            <Route path="/:playerType/:playerId" element={<SummaryPage serviceList={serviceList}/>}></Route>
            <Route path="/playerlist/:searchColumn/:searchStr" element={<PlayerListPage serviceList={serviceList}/>}></Route>
            <Route path="/*" element={<NotFoundPage/>}></Route>
          </Route>
        }
      </Routes>
    </Router>
  )
}

export default App;
