import React from 'react';
import BallIcon from '../../assets/img/icon/ball_icon.png';
import PennantRaceRecord from './sub/PennantRaceRecord';
import FuturesRecord from './sub/FuturesRecord';

function RecordMain({playerId, playerType, leagueType}) {

    return (
        <>
            <div className="recordWrap">
                <div id="recordTitle">
                    <img src={BallIcon} alt=""/>
                    최근 기록
                </div>
                {
                    leagueType === "kbo" &&
                    <PennantRaceRecord playerId={playerId} playerType={playerType} leagueType={leagueType}/>
                }
                {
                    leagueType === "futures" &&
                    <FuturesRecord playerId={playerId} playerType={playerType}/>
                }
            </div>
        </>
    )
}

export default RecordMain;