import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import _ from 'lodash';
import serverConfig from '../utils/api/serverConfig';
import { Route, Navigate, Outlet} from 'react-router-dom';
import NotFoundPage from './NotFoundPage';

function AuthPage(props) {
    const [cookies] = useCookies(['hiball_token']);
    let [authValue, setAuthValue] = useState(false);

    useEffect(() => {
        const token = cookies['hiball_token'];
        if(token === undefined || token === 'undefined') {
            setAuthValue(false);
        } else {
            setAuthValue(true);
        }

        // eslint-disable-next-line
    }, [authValue]);

    const navigateHandler = (token) => {
        getAuthConfig()
        .then(config => {
            let callPath = makeServiceAccessCheckPath(token);
            let fullPath = config.protocolType + config.authHost+callPath;
            authCheckCall(fullPath, token, config);
        });
    }

    function makeServiceAccessCheckPath(token) {
        let callPath = "";
        let authCookie = JSON.stringify(token);
    
        if(authCookie === undefined) {
            callPath = "/service/access?serviceType=" + serverConfig.serviceType;
        } else {
            callPath = "/service/access?authToken=" + authCookie + "&serviceType=" + serverConfig.serviceType;
        }
        
        return encodeURI(callPath);
    }

    function makeServiceAccessPath(token) {
        let callPath = "";
        let authCookie = JSON.stringify(token);
        callPath = "/services?authToken=" + authCookie;
    
        return encodeURI(callPath);
    }

    function authCheckCall(path, token, config) {
        var authFullHost = config.protocolType + config.authHost;
        // var homeUrl = config.homeHost;
        var loginUrl = config.protocolType + config.authHost + config.loginPath;
        // var logoutUrl = config.protocolType + config.authHost + config.logoutPath;

        axios.get(path).then((data) => {
            try {
                let result = data['data'];
                let executeCode = result.executeCode;

                // eslint-disable-next-line
                if(executeCode == 200) {
                    axios.get(authFullHost + makeServiceAccessPath(token)).then((data) => {
                        let serviceList = data['data']['serviceList'];
                        // console.log(serviceList);

                        props.setServiceList(serviceList);
                    });
                // eslint-disable-next-line
                } else if(executeCode == 5002) {
                    window.location.href = loginUrl+'?loginRequire=true';
                } else {
                    return <Route path="/" element={<NotFoundPage />} />
                }
            } catch(err) {
                console.log(err);
                return <Navigate replace to={loginUrl}/>
            }
        });
    }

    function getAuthConfig() {
        return new Promise(function(resolve, reject) {
            let callPath = serverConfig.authServerHost + "/customer/teams";
    
            axios.get(callPath).then((data) => {
                let result = null;
                if(data) {
                    let {customerTeams} = data['data'];
                    _.forEach(customerTeams, (item) => {
                        // eslint-disable-next-line
                        if(item['teamName'] == serverConfig.serviceTeam) {
                            result = item;
                            resolve(result);
                        }
                    });

                    if(!result) {
                        return <Route path="/" element={<NotFoundPage />} />
                    }
                } else {
                    return <Route path="/" element={<NotFoundPage />} />
                }
            });
        });
    }


    return (
        authValue ? 
        <Outlet/> :
        navigateHandler(cookies['hiball_token'])
    )
}

export default AuthPage;