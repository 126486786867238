import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import _ from 'lodash';
import SearchForm from './sub/SearchForm';
import SearchFormForSummary from './sub/SearchFormForSummary';
import { useCookies } from 'react-cookie';
import dateSetting from '../../utils/dateSetting';

function SearchBar({page}) {
    let [playerArr, setPlayerArr] = useState([]);
    let [proTeamArr, setProTeamArr] = useState([]);
    let [amateurTeamArr, setAmateurTeamArr] = useState([]);
    let [selectedCategory, setSelectedCategory] = useState("all");
    let [selectedDepartment, setSelectedDepartment] = useState("all");
    let [selectedTeam, setSelectedTeam] = useState("all");
    let inputRef = useRef();
    const [cookies] = useCookies(['hiball_token']);

    function formSubmitHandler(event) {
        let value = inputRef.current.value;

        // console.log(value);

        if(selectedTeam === "all"){
            if(value !== ""){
                try {
                    validationFunc(value);
                    searchFunc(value);
                } catch(err) {

                }
            } else {
                alert("팀단위 검색 혹은 선수명을 입력해주세요.");
            }
        } else {
            searchFunc(value);
        }

        event.preventDefault();
    }

    function validationFunc(playerName) {
        if(isNaN(playerName)) { // string
            // eslint-disable-next-line
            let rc = '[a-z|A-Z|\*|\?|\!|\@|\#|\$|\%|\^|\&]+';
            let reg = new RegExp(rc);
            let t = reg.exec(playerName);

            if(t != null) {
                alert("한글만 입력가능합니다.");
                throw new Error("한글만 입력가능합니다.");
            }

            if(playerName.length < 2) {
                alert('검색 선수명이 최소 2글자 이상이어야 합니다.');
                throw new Error('검색 선수명이 최소 2글자 이상이어야 합니다.')
            }
        } else { //number

        }
    }

    function searchFunc(playerName) {
        let dateObj = dateSetting();
        let params = {
            recordType: 'profileService',
            recordName: 'playerAllProfile',
            authToken: JSON.stringify(cookies['hiball_token']),
            seasons: dateObj['season']
        }

        if(selectedCategory === "pro"){
            params['groupingColumn'] = "pro";
        } else if(selectedCategory === "amateur"){
            params['groupingColumn'] = "ama";
        }

        if(selectedTeam !== "all"){
            params['teamId'] = selectedTeam;
        } else {
            if(selectedDepartment !== "all"){
                let teamIds = [];

                if(selectedDepartment === "pro"){
                    _.forEach(proTeamArr, (item) => {
                        teamIds.push(item.teamId);
                    });
                } else {
                    _.forEach(amateurTeamArr, (item) => {
                        teamIds.push(item.scoutTeamId);
                    });
                }

                params['teamIds'] = teamIds.toString();
            }
        }

        if(playerName !== ""){
            if(isNaN(playerName)) { //string
                params['membersName'] = playerName;
            } else { //number
                params['backNumber'] = playerName;
            }
        }

        let searchProfile = axios({
            url: `${process.env.REACT_APP_API_URL}`, params
        });

        Promise.all([
            searchProfile
        ]).then(value => {
            let profiles = value[0]['data']['result'];

            // console.log(profiles);

            let sortedProfiles = _.sortBy(profiles, ['membersName']);

            // console.log(sortedProfiles);

            setPlayerArr(sortedProfiles);
        })
    }

    useEffect(() => {
        let teamInfoParams = {
            recordType: 'profileService',
            recordName: 'teamInfo',
            authToken: JSON.stringify(cookies['hiball_token']),
            gameTypeCode: 4201
        }

        let proTeamInfo = axios({
            url: `${process.env.REACT_APP_API_URL}`,
            params: teamInfoParams
        });

        let amateurTeamInfo = axios({
            url: `${process.env.REACT_APP_SCOUTING_RECORD_URL}/scoutTeamInfo/teamInfo`,
            params: {}
        });

        Promise.all([
            proTeamInfo, amateurTeamInfo
        ]).then(value => {
            let proTeam = value[0]['data']['result'];
            let amateurTeam = value[1]['data']['RECORD'];

            // console.log(proTeam);
            // console.log(amateurTeam);

            setProTeamArr(proTeam);

            let sortedAmateurTeam = _.sortBy(amateurTeam, ['scoutTeamName']);

            // console.log(selectedDepartment);
            // console.log(sortedAmateurTeam);

            if(selectedDepartment !== "all"){
                let filteredAmateurTeam = _.filter(sortedAmateurTeam, (item) => {
                    return item['scoutTeamTypeCodeId'] * 1 === selectedDepartment * 1;
                });

                // console.log(filteredAmateurTeam);

                setAmateurTeamArr(filteredAmateurTeam);
            } else {
                setAmateurTeamArr(sortedAmateurTeam);
            }



        })
    },[cookies, selectedDepartment]);

    return (
        <>
        {
            page === "main" ?
            <SearchForm playerArr={playerArr} proTeamArr={proTeamArr} amateurTeamArr={amateurTeamArr}
            formSubmitHandler={formSubmitHandler} inputRef={inputRef}
            selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory}
            selectedDepartment={selectedDepartment} setSelectedDepartment={setSelectedDepartment} setSelectedTeam={setSelectedTeam}/> :
            <SearchFormForSummary playerArr={playerArr} formSubmitHandler={formSubmitHandler} inputRef={inputRef}/>
        }
        </>
    )
}

export default SearchBar;