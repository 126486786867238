import React, { useEffect } from 'react';
import NullPlayer from '../../../assets/img/player/null.png';

function SearchList({data}) {
    let teamIdArr = [1001, 2001, 3001, 4001, 5001, 6001, 7001, 8001, 9001, 10001];
    let pitcherCodeArr = [5201, 5211, 9801];

    function onErrorHandler(event) {
        event.target.src = NullPlayer;
    }

    const playerProfileHandler = (event) => {
        let id = event.currentTarget.id;

        let idSplit = id.split('_');
        let positionTypeCode = idSplit[0];
        let playerId = idSplit[1];

        let playerType = (pitcherCodeArr.indexOf(positionTypeCode*1) > -1) ? 'pitcher' : 'batter';
        window.location.href = "/"+playerType+"/"+playerId;
    }

    function handCheck(hand) {
        if(hand ==='R') return '우';
        else return '좌';
    }

    function positionCheck(position) {
        if(pitcherCodeArr.indexOf(position*1) > -1) return '투수';
        else return '타자';
    }

    function teamCheck(team){
        return teamIdArr.indexOf(team) > -1 ? true : false;
    }

    function langCheck(str){
        if(str === "SSG" || str === "LG" || str === "kt" || str === "KT" || str === "KIA" || str === "NC"){
            return false;
        }

        let pattern = /[가-힣]/;
        return !pattern.test(str.trim().slice(0, 1));
    }

    function retireCheck(team){
        if(!Number.isNaN(team)){
            team += "";
        }

        if(team === "99901"){
            return "해외이적";
        } else if(team === "99902") {
            return "입대";
        } else if(team === "99990") {
            return "방출";
        } else if(team === "99999") {
            return "은퇴";
        } else {
            return "";
        }
    }

    useEffect(() => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);

        window.addEventListener('resize', () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        })
    }, []);

    return (
        <>
        {
        data.map((profile, index) => (
            <div key={index} className={"playerCard team" + profile['teamId'] + "-border"}
            id={profile['positionTypeCode'] + "_" + (profile['membersId'] !== 0 ? profile['membersId'] : "s" + profile['scoutPlayerMembersId'])} onClick={playerProfileHandler}>
                <div className="basicInfo">
                    <div className="playerNameWrap">
                        {
                        profile['membersName'] && 
                        (
                        langCheck(profile['membersName']) ?
                        <div className="playerName englishName">
                            <div>{profile['membersName'].trim().split(" ")[0]}</div>
                            {
                            profile['membersName'].split(" ")[1] &&
                            <div>{profile['membersName'].trim().split(" ")[1]}</div>
                            }
                        </div> :
                        <div className="playerName">
                            <div>{profile['membersName'].trim()}</div>
                        </div>
                        )
                        }
                        {
                        profile['teamId'] &&
                        (
                        retireCheck(profile['teamId']) !== "" &&
                        <div className="playerRetireReason">
                            ({retireCheck(profile['teamId'])})
                        </div>
                        )
                        }
                    </div>
                    <div className="teamLogo">
                        {
                        profile['teamName'] &&
                        (
                        teamCheck(profile['teamId']) ?
                        <div className={"teamImg team" + profile['teamId'] + "-logo"}></div> :
                        langCheck(profile['teamName']) ?
                        <div className="teamName englishName">
                            <div>{profile['teamName'].trim().split(" ")[0]}</div>
                            {
                            profile['teamName'].split(" ")[1] &&
                            <div>{profile['teamName'].trim().split(" ")[1]}</div>
                            }
                        </div> :
                        <div className="teamName">
                            {profile['teamName'].trim()}
                        </div>
                        )
                        }
                    </div>
                </div>
                <div className="detailInfo">
                    <div className="playerImg">
                        <img src={`http://image.hanwha.kbostat.net/profileImage/${profile['membersId']}.jpg`}
                            alt="#" onError={onErrorHandler} />
                    </div>
                    <div className="playerInfo">
                        <div>등번호 {profile['backnumber']}</div>
                        <div className="playerPitchingType">{handCheck(profile['pitchingHand'])}투{handCheck(profile['battingHand'])}타</div>
                        <div className="position">{positionCheck(profile['positionTypeCode'])}</div>
                    </div>
                </div>
            </div>  
        ))
        }
        </>
    )
}

export default SearchList;