import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function SubMenu(props) {
    const serviceList =  props.serviceList;
    // console.log(serviceList);

    return (
        <>
            <div className="hb_header_submenu_container">
                <Container>
                    <Row>
                        <Col xs={12} id="service_scroll" style={{height: '46px', display: 'flex', alignItems: 'center'}}>
                        {
                            serviceList.length > 0 &&
                            serviceList.map((item, index) => (
                                <a target="_blank" rel="noreferrer" href={item.url} style={{position: 'relative'}} key={index}>
                                {
                                    item.serviceId === "info" ?
                                    <>
                                        <span className="header_on"></span>
                                        <span className="header_menu_btn on" name="homeBtn">
                                            {item.serviceName}
                                        </span>
                                    </> :
                                    <span className="header_menu_btn" name="homeBtn">
                                        {item.serviceName}
                                    </span>
                                }
                                </a>
                            ))
                        }
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default SubMenu;