import _ from "lodash";

export default function statFormat(dataObj) {
    let newDataObj = _.cloneDeep(dataObj);
    newDataObj['ops'] = statString(dataObj['slg']*1+dataObj['obp']*1, 'zero');
    newDataObj['xba_avg'] = statString(dataObj['xba_avg'], 'zero');
    newDataObj['xwoba_avg'] = statString(dataObj['xwoba_avg']*1, 'zero');
    newDataObj['exit_speed_avg'] = statString(dataObj['exit_speed_avg'], 'toFixedOne');
    newDataObj['max_speed'] = statString(dataObj['max_speed'], 'toFixedOne');

    newDataObj['strong_hit_pct'] = statString(dataObj['strong_hit_pct'], 'percent');
    newDataObj['barrel_pct'] = statString(dataObj['barrel_pct'], 'percent');
    newDataObj['eft_hit_pct'] = statString(dataObj['eft_hit_pct'], 'percent');

    newDataObj['slg_pct'] = statString(dataObj['slg_pct'], 'percent');

    newDataObj['IN_ZONE_CONTACT_PCT'] = statString(dataObj['IN_ZONE_CONTACT_PCT'], 'percent');
    newDataObj['so_pct'] = statString(dataObj['so_pct'], 'percent');
    newDataObj['LINE_DRIVE_PCT'] = statString(dataObj['LINE_DRIVE_PCT'], 'percent');

    newDataObj['chase_pct'] = statString(dataObj['chase_pct'], 'percent');
    newDataObj['bb_pct'] = statString(dataObj['bb_pct'], 'percent');
    newDataObj['swing_pct'] = statString(dataObj['swing_pct'], 'percent');

    newDataObj['pitch_pa_avg'] = statString(dataObj['pitch_pa_avg'], 'toFixedOne');
    newDataObj['bb9'] = statString(dataObj['bb9'], 'toFixedStatTwo');
    newDataObj['k9'] = statString(dataObj['k9'], 'toFixedStatTwo');
    newDataObj['ground_popup_rate'] = statString(dataObj['ground_popup_rate'], 'percent');
    newDataObj['avg'] = statString(dataObj['avg'], 'zero');
    newDataObj['xslg_avg'] = statString(dataObj['xslg_avg'], 'zero');
    newDataObj['whip'] = statString(dataObj['whip'], 'toFixedStatTwo');
    newDataObj['era'] = statString(dataObj['era'], 'toFixedStatTwo');
    newDataObj['fastball_speed_avg'] = statString(dataObj['fastball_speed_avg'], 'toFixedOne');
    newDataObj['fastball_spinrate_avg'] = statString(dataObj['fastball_spinrate_avg'], 'int');
    newDataObj['strike_rate'] = statString(dataObj['strike_rate'], 'percent');
    newDataObj['b_swing_rate'] = statString(dataObj['b_swing_rate'], 'percent');

    return newDataObj;
}

function statString(stat, type) {
    let statStringInit = '';

    if(type === 'zero') {
        statStringInit = zeroLeadingStat(stat);
    } else if(type === 'toFixedOne') {
        statStringInit = toFixedStat(stat);
    } else if(type === 'percent') {
        statStringInit = percentStat(stat);
    } else if(type === 'toFixedStatTwo') {
        statStringInit = toFixedStatTwo(stat);
    } else if(type === 'int') {
        statStringInit = parseIntStat(stat);
    }
    
    return statStringInit;
}

function zeroLeadingStat(data) {
    let stringData = '';
    if(data*1 === 0) {
        stringData = '.000';
    } else if(Number.isInteger(data)) { // integer
        stringData = data+".000";
    } else if(data > 0 && data < 1)  {
        let basicStat = (data*1).toPrecision(3);
        stringData = basicStat.slice(1,5);
    } else if(data > 1){ 
        let basicStat = (data*1).toPrecision(4);
        stringData = basicStat;
    }

    return stringData;
}

function parseIntStat(data) {
    return parseInt(data);
}

function percentStat(data) {
    let fixedData = (data*100).toFixed(1);
    return fixedData;
}

function toFixedStat(data) {
    return (data*1).toFixed(1);
}

function toFixedStatTwo(data) {
    return (data*1).toFixed(2);
}