import React, { useState, useEffect } from 'react';
import _ from 'lodash';

function ProfileRecordR({profile}) {
    let [careerArr, setCareerArr] = useState([]);
    let [draftArr, setDraftArr] = useState([]);

    function convertUnderscoreToHyphen(str){
        return str ? str.replace(/_/g, '-') : "";
    }

    function separateDraftStr(str){
        return str;
    }

    const deptNameHandler = (event) => {
        let value = event.currentTarget.innerHTML;
        let splitedValue = value.split("(")[0];

        if(splitedValue === ""){
            let str = value.split("(")[1];
            splitedValue = str.split(")")[0];
        }

        // console.log(splitedValue);

        window.location.href = "/playerlist/career/" + splitedValue.trim();
    }

    const draftHandler = (event) => {
        let value = event.currentTarget.innerHTML;

        // console.log(value);

        if(value.indexOf("라운드") > 0 || value.indexOf("순위") > 0){
            window.location.href = "/playerlist/draft/ " + value.trim();
        } else {
            window.location.href = "/playerlist/draft/" + value.trim();
        }
    }

    useEffect(() => {
        let careerSummary = convertUnderscoreToHyphen(profile['careerSummary']);

        if(careerSummary !== ""){
            let splitedCareer = careerSummary ? careerSummary.split("-") : "";
            setCareerArr(splitedCareer);
        }

        let firstJoinOrder = separateDraftStr(profile['firstJoinOrder']);

        if(firstJoinOrder !== ""){
            let splitedFirstJoinOrder = firstJoinOrder ? firstJoinOrder.split(" ") : "";

            let draftStrArr = [];
            let tempStr = "";
            let separateFlag = false;

            _.forEach(splitedFirstJoinOrder, (item, index) => {
                if(item.indexOf("차") > -1 || item.indexOf("라운드") > -1 || item.indexOf("자유선발") > -1){
                    separateFlag = true;
                }

                if(separateFlag){
                    draftStrArr.push(tempStr);
                    tempStr = "";
                }

                if(index === 0){
                    tempStr += item;
                } else {
                    tempStr += " " + item;
                }

                if(index === splitedFirstJoinOrder.length - 1){
                    draftStrArr.push(tempStr);
                }
            });

            // console.log(draftStrArr);

            setDraftArr(draftStrArr);
        }
    },[profile]);

    return (
        <>
            <div className="dataTable">
                <table>
                    <colgroup>
                        <col width="20%"/>
                        <col width="30%"/>
                        <col width="20%"/>
                        <col width="30%"/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>에이전트</th>
                            <td></td>
                            <th>신장/체중</th>
                            <td>{profile['height']}cm / {profile['weight']}kg</td>
                        </tr>
                        <tr>
                            <th>지명순위</th>
                            {
                            draftArr.length > 0 ?
                            <td colSpan="3">
                                {
                                draftArr.map((item, index) => (
                                    <span key={index}>
                                        {index !== 0 && " "}
                                        <span className="playerListLink" onClick={draftHandler}>
                                            {item}
                                        </span>
                                    </span>
                                ))
                                }
                            </td>
                            :
                            <td colSpan="3"></td>
                            }
                        </tr>
                        <tr>
                            <th>학력정보</th>
                            {
                            careerArr.length > 0 ?
                            <td colSpan="3">
                                {
                                careerArr.map((item, index) => (
                                    <span key={index}>
                                        {index !== 0 && "-"}
                                        <span className="playerListLink" onClick={deptNameHandler}>
                                            {item}
                                        </span>
                                    </span>
                                ))
                                }
                            </td>
                            :
                            <td colSpan="3"></td>
                            }
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default ProfileRecordR;