import React, { useEffect, useState } from 'react';
import axios from "axios";
import _ from 'lodash';
import { flatObject } from "../../../utils/flat";
import { useCookies } from 'react-cookie';
import dateSetting from '../../../utils/dateSetting';
import DefaultLoader from "../../../components/loader/DefaultLoader";

function FuturesRecord({playerId, playerType}) {
    let dateObj = dateSetting();
    const thisYear = dateObj['season'];
    const [cookies] = useCookies(['hiball_token']);
    let [loading, setLoading] = useState(false);
    let [seasonRecord, setSeasonRecord] = useState({});

    function updateSeasonRecord(key, data){
        setSeasonRecord((prevState) => {
            return {...prevState, [key]: data};
        });
    }

    const batterColumnSet = [
        {column: '시즌', property: 'season'},
        // {column: '나이', property: 'age'},
        {column: '출장', property: 'basicStat_gameCnt'},
        {column: '타석', property: 'basicStat_tpa'},
        {column: '안타', property: 'basicStat_hit'},
        {column: '2루타', property: 'basicStat_b2b'},
        {column: '3루타', property: 'basicStat_b3b'},
        {column: '홈런', property: 'basicStat_hr'},
        {column: '도루', property: 'basicStat_sbSucc'},
        {column: '삼진', property: 'basicStat_so'},
        {column: '볼넷', property: 'basicStat_bb'},
        {column: '타율', property: 'avg'},
        {column: '출루율', property: 'obp'},
        {column: '장타율', property: 'slg'},
        {column: 'OPS', property: 'ops'},
        {column: 'BABIP', property: 'babip'},
        {column: '삼진%', property: 'kRate'},
        {column: '볼넷%', property: 'bbRate'},
        // {column: '강한\n타구%(T)', property: 'hardHitRate'},
        // {column: '배럴%\n(T)', property: 'barrelRate'},
        // {column: '유효\n타구%(T)', property: 'vaildHitRate'}
    ];

    const pitcherColumnSet = [
        {column: '시즌', property: 'season'},
        // {column: '나이', property: 'age'},
        {column: '경기', property: 'basicStat_gameCnt'},
        {column: '선발', property: 'starter'},
        {column: '승', property: 'win'},
        {column: '패', property: 'lose'},
        {column: '세이브', property: 'save'},
        {column: '홀드', property: 'hold'},
        {column: '이닝', property: 'ip'},
        {column: '타석', property: 'basicStat_tpa'},
        {column: '삼진', property: 'basicStat_so'},
        {column: '볼넷', property: 'basicStat_bb'},
        {column: '피홈런', property: 'basicStat_hr'},
        {column: 'ERA', property: 'era'},
        {column: 'FIP', property: 'fip'},
        {column: 'WHIP', property: 'whip'},
        {column: 'BABIP', property: 'babip'},
        {column: '삼진/9', property: 'k9'},
        {column: '볼넷/9', property: 'bb9'},
        {column: '홈런/9', property: 'hr9'}
    ];

    useEffect(() => {
        let apiParam = {
            seasons: [thisYear-2, thisYear-1, thisYear].toString(),
            gameTypeCodes: [4206, 4208, 4213].toString(),
            groupingColumn:'infoSeason',
            authToken:JSON.stringify(cookies['hiball_token'])
        }

        if(playerType === "batter"){
            apiParam['recordType'] = "digestBattingRecord";
            apiParam['recordName'] = "seasonRecord";
            apiParam['batterIds'] = playerId;
        } else {
            apiParam['recordType'] = "digestPitchingRecord";
            apiParam['recordName'] = "selectSeasonRecord";
            apiParam['pitcherIds'] = playerId;
        }

        let basicRecord = axios({
            url : `${process.env.REACT_APP_API_URL}`,
            params: apiParam
        });
        
        Promise.all([
            basicRecord
        ]).then((result) => {
            let record = result[0]['data']['result'];
            let groupingSeasonRecord = _.keyBy(record, 'season');

            // console.log(groupingSeasonRecord);

            for(let i = thisYear-2; i <= thisYear; i++){
                if(groupingSeasonRecord[i]){
                    updateSeasonRecord(i, flatObject(groupingSeasonRecord[i]));
                }
            }

            setLoading(true);
        })
    },[cookies, playerType, playerId, thisYear]);

    if(!loading) {
        return <div style={{height: '135px'}}><DefaultLoader/></div>
    }

    return (
        <>
        {
            playerType === "batter" &&
            <div id="recentRecordTable">
                <table>
                    <thead>
                        <tr>
                        {
                        batterColumnSet.map((item, index) => (
                            <th key={index}>
                                {item['column']}
                            </th>
                        ))
                        }
                        </tr>
                    </thead>
                    <tbody>
                        {
                        (seasonRecord[thisYear-2] && seasonRecord[thisYear-2]['basicStat_gameCnt']*1 !== 0) ?
                        <tr>
                        {
                        batterColumnSet.map((item, index) => (
                            <td key={index}>{seasonRecord[thisYear-2][item['property']]}</td>
                        ))
                        }
                        </tr> :
                        <tr>
                        {
                        <>
                        <td>{thisYear-2}</td>
                        <td colSpan={16}>No Data</td>
                        </>
                        }
                        </tr>
                        }
                        {
                        (seasonRecord[thisYear-1] && seasonRecord[thisYear-1]['basicStat_gameCnt']*1 !== 0) ?
                        <tr>
                        {
                        batterColumnSet.map((item, index) => (
                            <td key={index}>{seasonRecord[thisYear-1][item['property']]}</td>
                        ))
                        }
                        </tr> :
                        <tr>
                        {
                        <>
                        <td>{thisYear-1}</td>
                        <td colSpan={16}>No Data</td>
                        </>
                        }
                        </tr>
                        }
                        {
                        (seasonRecord[thisYear] && seasonRecord[thisYear]['basicStat_gameCnt']*1 !== 0) ?
                        <tr>
                        {
                        batterColumnSet.map((item, index) => (
                            <td key={index}>{seasonRecord[thisYear][item['property']]}</td>
                        ))
                        }
                        </tr> :
                        <tr>
                        {
                        <>
                        <td>{thisYear}</td>
                        <td colSpan={16}>No Data</td>
                        </>
                        }
                        </tr>
                        }
                    </tbody>
                </table>
            </div>
        }
        {
            playerType === "pitcher" &&
            <div id="recentRecordTable">
                <table>
                    <thead>
                        <tr>
                        {
                        pitcherColumnSet.map((item, index) => (
                            <th key={index}>
                                {item['column']}
                            </th>
                        ))
                        }
                        </tr>
                    </thead>
                    <tbody>
                        {
                        (seasonRecord[thisYear-2] && seasonRecord[thisYear-2]['basicStat_gameCnt']*1 !== 0) ?
                        <tr>
                        {
                        pitcherColumnSet.map((item, index) => (
                            <td key={index}>{seasonRecord[thisYear-2][item['property']]}</td>
                        ))
                        }
                        </tr> :
                        <tr>
                        {
                        <>
                        <td>{thisYear-2}</td>
                        <td colSpan={18}>No Data</td>
                        </>
                        }
                        </tr>
                        }
                        {
                        (seasonRecord[thisYear-1] && seasonRecord[thisYear-1]['basicStat_gameCnt']*1 !== 0) ?
                        <tr>
                        {
                        pitcherColumnSet.map((item, index) => (
                            <td key={index}>{seasonRecord[thisYear-1][item['property']]}</td>
                        ))
                        }
                        </tr> :
                        <tr>
                        {
                        <>
                        <td>{thisYear-1}</td>
                        <td colSpan={18}>No Data</td>
                        </>
                        }
                        </tr>
                        }
                        {
                        (seasonRecord[thisYear] && seasonRecord[thisYear]['basicStat_gameCnt']*1 !== 0) ?
                        <tr>
                        {
                        pitcherColumnSet.map((item, index) => (
                            <td key={index}>{seasonRecord[thisYear][item['property']]}</td>
                        ))
                        }
                        </tr> :
                        <tr>
                        {
                        <>
                        <td>{thisYear}</td>
                        <td colSpan={18}>No Data</td>
                        </>
                        }
                        </tr>
                        }
                    </tbody>
                </table>
            </div>
        }
        </>
    )
}

export default FuturesRecord;