import React, { useEffect, useState } from 'react';
import axios from "axios";
import _ from 'lodash';
import { useNavigate } from "react-router-dom";
import { useCookies } from 'react-cookie';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useParams } from "react-router-dom";
import ProfileMain from "./profile/ProfileMain";
import RecordMain from "./record/RecordMain";
import ChartMain from "./chart/ChartMain";
import ReportMain from "./report/ReportMain";
import ScoutingMain from "./scouting/ScoutingMain";
import MedicalMain from "./medical/MedicalMain";
// import MemoMain from "./memo/MemoMain";
import Header from "../components/header/Header";
import SearchBar from "../components/searchBar/SearchBar";
import '../assets/css/summary.css';

function SummaryPage(props) {
    let [leagueType, setLeagueType] = useState("kbo");
    const [cookies] = useCookies(['hiball_token']);
    const navigate = useNavigate();

    const { playerType, playerId } = useParams();

    let [profile, setProfile] = useState({
        playerId: null, playerName: null, englishName: null,
        teamId: null, teamName: null,
        battingHand: null, pitchingHand: null,
        birthdate: null, position: null, backNumber: null,
        height: null, weight: null
    });

    let [membersId, setMembersId] = useState({});

    const onSwitchPosition = (event) => {
        let id = event.currentTarget.id;

        let idSplit = id.split('_');
        let positionStr = idSplit[1];

        window.location.href = "/"+positionStr+"/"+playerId;
    }

    const onSwitchLeague = (event) => {
        let id = event.currentTarget.id;

        let idSplit = id.split('_');
        let leagueTypeStr = idSplit[1];

        setLeagueType(leagueTypeStr);
    }

    useEffect(() => {
        let apiParam = {
            recordType: 'digestRecord', recordName: 'selectAllProfile',
            authToken:JSON.stringify(cookies['hiball_token'])
        };

        let replacedPlayerId = playerId.replace('s', '') * 1;

        if(playerType === "batter"){
            apiParam['batterIds'] = replacedPlayerId;
        } else {
            apiParam['pitcherIds'] = replacedPlayerId;
        }

        axios({
            url : `${process.env.REACT_APP_API_URL}`,
            params: apiParam
        }).then((result) => {
            if(result.data.result.length < 1){
                navigate('/');
            } else {
                let playerData = result['data']['result'];

                let filteredPlayerData = _.filter(playerData, function(item){
                    if(playerType === "batter"){
                        if(playerId.includes('s')){
                            return item.batter.scoutPlayerMembersId === replacedPlayerId;
                        } else {
                            return item.batter.batterId === replacedPlayerId;
                        }
                    } else {
                        if(playerId.includes('s')){
                            return item.pitcher.scoutPlayerMembersId === replacedPlayerId;
                        } else {
                            return item.pitcher.pitcherId === replacedPlayerId;
                        }
                    }
                });

                let {batter, pitcher} = filteredPlayerData[0];
                let flatResult = {
                    ...batter, ...pitcher
                }

                // console.log(flatResult);

                let englishName = null;
                if(flatResult['lastName'] !== null && flatResult['firstName'] !== null){
                    englishName = flatResult['lastName'] + ", " + flatResult['firstName'];
                }

                if(playerType === "batter"){
                    setProfile({
                        playerId: playerId,
                        playerName: flatResult['batterName'],
                        englishName: englishName,
                        teamId: filteredPlayerData[0]['teamId'],
                        teamName: filteredPlayerData[0]['teamName'],
                        battingHand: flatResult['battingHand'],
                        pitchingHand: flatResult['pitchingHand'],
                        birthdate: flatResult['birthdate'],
                        position: flatResult['position'],
                        backNumber: flatResult['backNumber'],
                        height: flatResult['height'],
                        weight: flatResult['weight'],
                        firstJoinYear: flatResult['firstJoinYear'],
                        firstJoinOrder: flatResult['firstJoinOrder'],
                        careerSummary: flatResult['careerSummary']
                    });

                    setMembersId({
                        playerMembersId: flatResult['batterId'],
                        scoutPlayerMembersId: flatResult['scoutPlayerMembersId']
                    });
                } else {
                    setProfile({
                        playerId: playerId,
                        playerName: flatResult['pitcherName'],
                        englishName: englishName,
                        teamId: filteredPlayerData[0]['teamId'],
                        teamName: filteredPlayerData[0]['teamName'],
                        battingHand: flatResult['battingHand'],
                        pitchingHand: flatResult['pitchingHand'],
                        birthdate: flatResult['birthdate'],
                        position: flatResult['position'],
                        backNumber: flatResult['backNumber'],
                        height: flatResult['height'],
                        weight: flatResult['weight'],
                        firstJoinYear: flatResult['firstJoinYear'],
                        firstJoinOrder: flatResult['firstJoinOrder'],
                        careerSummary: flatResult['careerSummary']
                    });

                    setMembersId({
                        playerMembersId: flatResult['pitcherId'],
                        scoutPlayerMembersId: flatResult['scoutPlayerMembersId']
                    });
                }
            }
        });
    }, [cookies, navigate, playerType, playerId])

    return (
        <>
            <Header serviceList={props.serviceList}/>
            <div id="summaryContainer">
                <Container>
                    <Row>
                        <div id="funcBar">
                            <div id="positionBtn">
                                <button id="position_batter" className={playerType === "batter" ? "on" : ""} onClick={onSwitchPosition}>타자</button>
                                <button id="position_pitcher" className={playerType === "pitcher" ? "on" : ""} onClick={onSwitchPosition}>투수</button>
                            </div>
                            <SearchBar page={"summary"}/>
                        </div>
                    </Row>
                    <Row id="playerProfileRow">
                        <ProfileMain profile={profile}/>
                    </Row>

                    {
                    Object.keys(membersId).length !== 0 &&
                    <>
                    {
                    membersId.playerMembersId !== 0 &&
                    <Row>
                        <div id="gameRecordBox">
                            <div id="menuWrap">
                                <ul id="menu">
                                    <li id="league_kbo" className={leagueType === "kbo" ? "on" : ""} onClick={onSwitchLeague}>KBO</li>
                                    <li id="league_futures" className={leagueType === "futures" ? "on" : ""} onClick={onSwitchLeague}>퓨처스/연습</li>
                                </ul>
                            </div>
                            <div id="gameRecordWrap">
                                <div id="gameRecordBg">
                                    <RecordMain playerId={playerId} playerType={playerType} leagueType={leagueType}/>
                                    {
                                    leagueType === "kbo" &&
                                    <>
                                    <hr/>
                                    <ChartMain playerId={playerId} playerType={playerType} leagueType={leagueType}/>
                                    </>
                                    }
                                </div>
                            </div>
                        </div>
                    </Row>
                    }
                    {
                    membersId.playerMembersId !== 0 &&
                    <Row>
                        <ReportMain playerId={playerId} playerType={playerType}/>
                    </Row>
                    }
                    <Row>
                        <ScoutingMain membersId={membersId}/>
                    </Row>
                    {
                    membersId.playerMembersId !== 0 &&
                    <Row>
                        <MedicalMain playerId={playerId}/>
                    </Row>
                    }
                    {/* <Row>
                        <MemoMain playerId={playerId}/>
                    </Row> */}
                    </>
                    }
                </Container>
            </div>
        </>
    )
}

export default SummaryPage;