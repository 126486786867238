import React, { useEffect, useState, useRef } from 'react';
import axios from "axios";
import _ from 'lodash';
import BallIcon from '../../assets/img/icon/ball_icon.png';
import DefaultLoader from "../../components/loader/DefaultLoader";
import Pagination from '../../components/pagination/Pagination';

function ReportMain({playerId, playerType}) {
    let reportRef = useRef();
    let [reportType, setReportType] = useState(playerType);
    let [reportArr, setReportArr] = useState([]);
    let [loading, setLoading] = useState(false);

    let [currentPage, setCurrentPage] = useState(1);
    let [totalArticle, setTotalArticle] = useState(0);
    let articlePerPage = 5;

    let [reportCntObj, setReportCntObj] = useState({
        pitcher: 0,
        catcher: 0,
        fielder: 0,
        batter: 0,
        defense: 0,
        running: 0
    });

    const onSwitchPlayerType = (event) => {
        let id = event.currentTarget.id;

        let idSplit = id.split('_');
        let reportTypeStr = idSplit[1];

        setCurrentPage(1);
        setReportType(reportTypeStr);
    }

    useEffect(() => {
        const reportTypeObj = {
            pitcher: ["9601"], //투수
            catcher: ["9602"], //포수
            fielder: ["9603"], //야수
            batter: ["9604"], //타격
            defense: ["9605", "9606"], //내야수비, 외야수비
            running: ["9607"] //주루
        }

        axios({
            method: "post",
            url : `${process.env.REACT_APP_IPI_API_URL}/record/playerGameReports`,
            headers:{
                "Content-Type": 'application/json',
            },
            data: {
                playerId: playerId
            },
            responseType: "json"
        }).then((result) => {
            let reportList = result['data']['result'];

            _.forEach(reportList, function(item){
                if(item.awayTeamScore > item.homeTeamScore){
                    item['winningTeam'] = "away";
                } else if(item.awayTeamScore === item.homeTeamScore){
                    item['winningTeam'] = "draw";
                } else {
                    item['winningTeam'] = "home";
                }
            })

            // console.log(reportList);

            let groupingReportList = _.groupBy(reportList, (item) => {
                return item.reportingPositionCodeId;
            });

            // console.log(groupingReportList);

            let newReportCntObj = {};

            newReportCntObj['pitcher'] = groupingReportList['9601'] ? groupingReportList['9601'].length : 0;
            newReportCntObj['catcher'] = groupingReportList['9602'] ? groupingReportList['9602'].length : 0;
            newReportCntObj['fielder'] = groupingReportList['9603'] ? groupingReportList['9603'].length : 0;
            newReportCntObj['batter'] = groupingReportList['9604'] ? groupingReportList['9604'].length : 0;
            newReportCntObj['defense'] = (groupingReportList['9605'] ? groupingReportList['9605'].length : 0) + (groupingReportList['9606'] ? groupingReportList['9606'].length : 0);
            newReportCntObj['running'] = groupingReportList['9607'] ? groupingReportList['9607'].length : 0;

            setReportCntObj(newReportCntObj);

            let filteringReportList = _.filter(reportList, function(item){
                return reportTypeObj[reportType].includes(item.reportingPositionCodeId);
            });

            // console.log(filteringReportList);

            setTotalArticle(filteringReportList.length);

            let totalPage = Math.ceil(totalArticle / articlePerPage);
            let remainArticle = totalArticle % articlePerPage;

            let start = articlePerPage * (currentPage - 1);
            let end = articlePerPage * currentPage;

            if(totalPage === currentPage && remainArticle !== 0){
                end = start + remainArticle;
            }

            // console.log(start);
            // console.log(end);

            let sortedReportList = _.sortBy(filteringReportList, ['gameDay']).reverse();

            // console.log(sortedReportList);

            let slicedReportList = sortedReportList.slice(start, end);

            // console.log(slicedReportList);

            setReportArr(slicedReportList);

            setLoading(true);
        })
    },[playerId, articlePerPage, currentPage, totalArticle, reportType]);

    if(!loading) {
        return (
            <div className="recordWrap">
                <div id="recordTitle">
                    <img src={BallIcon} alt=""/>
                    경기 리포트
                </div>
                <div id="tabMenu">
                    <div className='on'>&nbsp;</div>
                    <div>&nbsp;</div>
                </div>
                <div id="reportBox">
                    <div style={{height: '200px'}}><DefaultLoader/></div>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="recordWrap" ref={reportRef}>
                <div id="recordTitle">
                    <img src={BallIcon} alt=""/>
                    경기 리포트
                </div>
                <div id="tabMenu">
                {
                playerType === "batter" &&
                <>
                    <div id="report_batter" className={reportType === "batter" ? "on" : ""} onClick={onSwitchPlayerType}>타격({reportCntObj['batter']})</div>
                    <div id="report_defense" className={reportType === "defense" ? "on" : ""} onClick={onSwitchPlayerType}>수비({reportCntObj['defense']})</div>
                    <div id="report_running" className={reportType === "running" ? "on" : ""} onClick={onSwitchPlayerType}>주루({reportCntObj['running']})</div>
                    <div id="report_catcher" className={reportType === "catcher" ? "on" : ""} onClick={onSwitchPlayerType}>포수({reportCntObj['catcher']})</div>
                    <div id="report_fielder" className={reportType === "fielder" ? "on" : ""} onClick={onSwitchPlayerType}>야수({reportCntObj['fielder']})</div>
                </>
                }
                {
                playerType === "pitcher" &&
                <>
                    <div id="report_pitcher" className={reportType === "pitcher" ? "on" : ""} onClick={onSwitchPlayerType}>투수({reportCntObj['pitcher']})</div>
                </>
                }
                </div>
                <div id="reportBox">
                {
                    playerType === "batter" &&
                    <div className="hiballTable">
                        <table>
                            <colgroup>
                                <col width="5%"/>
                                <col width="10%"/>
                                <col width="10%"/>
                                <col width="8%"/>
                                <col width="25%"/>
                                <col width="*"/>
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>No.</th>
                                    <th>경기일</th>
                                    <th>경기결과</th>
                                    <th>작성자</th>
                                    <th>기록</th>
                                    <th>내용</th>
                                </tr>
                            </thead>
                            <tbody>
                            {reportArr.length > 0 ?
                            reportArr.map((item, index) => (
                                <tr key={index}>
                                    <td>{(currentPage - 1) * 5 + index + 1}</td>
                                    <td>{item.gameDay}<br/>{item.gameTypeName}</td>
                                    <td>
                                        {item.awayTeamName}&nbsp;
                                        {item.winningTeam === "away" ? <span style={{color: 'red'}}>{item.awayTeamScore}</span> : item.awayTeamScore}
                                        :
                                        {item.winningTeam === "home" ? <span style={{color: 'red'}}>{item.homeTeamScore}</span> : item.homeTeamScore}
                                        &nbsp;{item.homeTeamName}
                                    </td>
                                    <td>{item.writeUserName}</td>
                                    <td>
                                        {item.batterRecords ? item.batterRecords.plateAppearance + "타석 " : null}
                                        {item.batterRecords ? item.batterRecords.hit + "안타 " : null}
                                        {item.batterRecords ? item.batterRecords.homeRuns + "홈런 ": null}
                                        {item.batterRecords ? item.batterRecords.strikeOut + "삼진 " : null}
                                        {item.batterRecords ? item.batterRecords.baseOnBalls + "볼넷" : null}
                                    </td>
                                    <td style={{textAlign: 'left', fontSize: '14px'}} dangerouslySetInnerHTML={{__html: (item.reportingContentJson ? item.reportingContentJson.replace(/\n/g, '<br/>') : "")}}></td>
                                </tr>
                            )) :
                            <tr>
                                <td colSpan="6">No Data</td>
                            </tr>
                            }
                            </tbody>
                        </table>
                    </div>
                }
                {
                    playerType === "pitcher" &&
                    <div className="hiballTable">
                        <table>
                            <colgroup>
                                <col width="5%"/>
                                <col width="10%"/>
                                <col width="10%"/>
                                <col width="8%"/>
                                <col width="25%"/>
                                <col width="*"/>
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>No.</th>
                                    <th>경기일</th>
                                    <th>경기결과</th>
                                    <th>작성자</th>
                                    <th>기록</th>
                                    <th>내용</th>
                                </tr>
                            </thead>
                            <tbody>
                            {reportArr.length > 0 ?
                            reportArr.map((item, index) => (
                                <tr key={index}>
                                    <td>{(currentPage - 1) * 5 + index + 1}</td>
                                    <td>{item.gameDay}<br/>{item.gameTypeName}</td>
                                    <td>
                                        {item.awayTeamName}&nbsp;
                                        {item.winningTeam === "away" ? <span style={{color: 'red'}}>{item.awayTeamScore}</span> : item.awayTeamScore}
                                        :
                                        {item.winningTeam === "home" ? <span style={{color: 'red'}}>{item.homeTeamScore}</span> : item.homeTeamScore}
                                        &nbsp;{item.homeTeamName}
                                    </td>
                                    <td>{item.writeUserName}</td>
                                    <td>
                                        {item.pitcherRecords ? "투구수 " + item.pitcherRecords.pitchingCnt + "개 " : null}
                                        {item.pitcherRecords ? "/ " + item.pitcherRecords.pitchInningValue + "이닝 " : null}
                                        <br/>
                                        {item.pitcherRecords ? item.pitcherRecords.atBat + "타수 " : null}
                                        {item.pitcherRecords ? item.pitcherRecords.hit + "안타 " : null}
                                        {item.pitcherRecords ? item.pitcherRecords.homeRuns + "홈런 " : null}
                                        {item.pitcherRecords ? item.pitcherRecords.strikeOut + "삼진 " : null}
                                        {item.pitcherRecords ? item.pitcherRecords.baseOnBalls + "볼넷" : null}
                                    </td>
                                    <td style={{textAlign: 'left', fontSize: '14px'}} dangerouslySetInnerHTML={{__html: (item.reportingContentJson ? item.reportingContentJson.replace(/\n/g, '<br/>') : "")}}></td>
                                </tr>
                            )) :
                            <tr>
                                <td colSpan="6">No Data</td>
                            </tr>
                            }
                            </tbody>
                        </table>
                    </div>
                }
                    <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalArticle={totalArticle} articlePerPage={articlePerPage} afterFocusRef={reportRef} />
                </div>
            </div>
        </>
    )
}

export default ReportMain;