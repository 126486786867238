import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from "axios";
import _ from 'lodash';
import PieChartBattedBallType from './sub/PieChartBattedBallType';
import PieChartBallType from './sub/PieChartBallType';
import { flatObject } from "../../utils/flat";
import { useCookies } from 'react-cookie';
import BallIcon from '../../assets/img/icon/ball_icon.png';
import dateSetting from '../../utils/dateSetting';
import DefaultLoader from "../../components/loader/DefaultLoader";

function ChartMain({playerId, playerType, leagueType}) {
    let dateObj = dateSetting();
    const thisYear = dateObj['season'];
    const [cookies] = useCookies(['hiball_token']);
    let [loading, setLoading] = useState(false);
    let [baseRecord, setBaseRecord] = useState({});

    useEffect(() => {
        if(playerType === "batter"){
            axios({
                url : `${process.env.REACT_APP_API_URL}`,
                params: {
                    recordType: 'digestBattingRecord',
                    recordName: 'selectTrackManRecord',
                    batterIds: playerId,
                    seasons: [thisYear-2, thisYear-1, thisYear].toString(),
                    groupingColumn: 'season',
                    gameTypeCode: 4201,
                    authToken:JSON.stringify(cookies['hiball_token'])
                }
            }).then((result) => {
                let trackManData = _.map(result['data']['result'], (item) => {
                    item['type'] = item['season']+"_"+item['batterId'];
                    return flatObject(item);
                });
    
                let indexingTrackManData = _.keyBy(trackManData, 'season');
    
                // console.log(indexingTrackManData);
    
                setBaseRecord({
                    [thisYear-2]: indexingTrackManData[thisYear-2],
                    [thisYear-1]: indexingTrackManData[thisYear-1],
                    [thisYear]: indexingTrackManData[thisYear]
                })
                setLoading(true);
            })
        } else {
            axios({
                url : `${process.env.REACT_APP_API_URL}`,
                params: {
                    recordType: 'digestPitchingRecord',
                    recordName: 'selectPitchBallCodeRecord',
                    pitcherIds: playerId,
                    seasons: [thisYear-2, thisYear-1, thisYear].toString(),
                    gameTypeCode: 4201,
                    authToken:JSON.stringify(cookies['hiball_token'])
                }
            }).then((result) => {
                let pitchData = [];
                _.forEach(result['data']['result'], (item, idx) => {
                    item['type'] = item['season']+"_"+item['playerId']+"_"+idx;
                    item['balltype'] = item['ballCode'];
                    let newPitchData = flatObject(item);
    
                    if(newPitchData['ab']) {
                        pitchData.push(newPitchData);
                    }
                });
    
                pitchData.reverse();
    
                let groupingPitchData = _.groupBy(pitchData, 'season');
    
                // console.log(groupingPitchData);
    
                setBaseRecord({
                    [thisYear-2]: groupingPitchData[thisYear-2],
                    [thisYear-1]: groupingPitchData[thisYear-1],
                    [thisYear]: groupingPitchData[thisYear]
                })
                setLoading(true);
            })
        }
    },[playerType, cookies, leagueType, playerId, thisYear]);

    if(!loading) {
        return (
        <>
        {
            playerType === "batter" &&
            <div className="recordWrap">
                <div id="recordTitle">
                    <img src={BallIcon} alt=""/>
                    타구유형
                </div>
                <div id="chartBox">
                    <div style={{height: '344px'}}><DefaultLoader/></div>
                </div>
            </div>
        }
        {
            playerType === "pitcher" &&
            <div className="recordWrap">
                <div id="recordTitle">
                    <img src={BallIcon} alt=""/>
                    구사율
                </div>
                <div id="chartBox">
                    <div style={{height: '344px'}}><DefaultLoader/></div>
                </div>
            </div>
        }
        </>
        )
    }

    return (
        <>
        {
            playerType === "batter" &&
            <div className="recordWrap">
                <div id="recordTitle">
                    <img src={BallIcon} alt=""/>
                    타구유형
                </div>
                <div id="chartBox">
                    <Row>
                        <Col md={4}>
                            <div className="chartTitle">{thisYear-2}</div>
                            <div className="chartWrap">
                                <PieChartBattedBallType record={baseRecord[thisYear-2]}/>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="chartTitle">{thisYear-1}</div>
                            <div className="chartWrap">
                                <PieChartBattedBallType record={baseRecord[thisYear-1]}/>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="chartTitle">{thisYear}</div>
                            <div className="chartWrap">
                                <PieChartBattedBallType record={baseRecord[thisYear]}/>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        }
        {
            playerType === "pitcher" &&
            <div className="recordWrap">
                <div id="recordTitle">
                    <img src={BallIcon} alt=""/>
                    구사율
                </div>
                <div id="chartBox">
                    <Row>
                        <Col md={4}>
                            <div className="chartTitle">{thisYear-2}</div>
                            <div className="chartWrap">
                                <PieChartBallType record={baseRecord[thisYear-2]}/>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="chartTitle">{thisYear-1}</div>
                            <div className="chartWrap">
                                <PieChartBallType record={baseRecord[thisYear-1]}/>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="chartTitle">{thisYear}</div>
                            <div className="chartWrap">
                                <PieChartBallType record={baseRecord[thisYear]}/>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        }
        </>
    )
}

export default ChartMain;