import React from 'react';
import _ from 'lodash';
import Chart from "react-apexcharts";

function PieChartBallType({record}){
    let totalPitchRate = 100;
    let pitchData = _.sortBy(record, 'pitchRate').reverse();
    // console.log(pitchData);

    const ballColorObj = {
        직구: '#416ab2',
        투심: '#219f0c',
        커터: '#ffc20b',
        슬라이더: '#e04e54',
        커브: '#3aa6b9',
        체인지업: '#ef7b15',
        포크: '#c495e1',
        기타: '#959595'
    };

    let labels = [];
    let value = [];
    let colors = [];

    _.forEach(pitchData, function(item){
        // console.log(item);
        labels.push(item.ballCodeName);
        let pitchRate = item.pitchRate * 100;
        value.push(pitchRate);
        totalPitchRate -= pitchRate;
        colors.push(ballColorObj[item.ballCodeName] ? ballColorObj[item.ballCodeName] : ballColorObj['기타']);
    });
    // console.log(labels);
    // console.log(value);
    // console.log(colors);

    if(totalPitchRate > 0){
        labels.push("기타");
        value.push(totalPitchRate);
        colors.push(ballColorObj['기타']);
    }

    let chartData = {
        series: value,
        options: {
            chart: {
                type: 'pie',
                toolbar: {
                    show: true,
                    export: {
                        csv: {
                            filename: '구사율',
                            headerCategory: '구종',
                            headerValue: '구사율'
                        },
                        svg: {
                            filename: '구사율'
                        },
                        png: {
                            filename: '구사율'
                        }
                    }
                }
            },
            labels: labels,
            colors: colors,
            responsive: [{
                breakpoint: 480
            }],
            legend: {
                position: 'bottom'
            },
            dataLabels: {
                style: {
                    fontSize: '15px',
                    fontWeight: 'bold',
                }
            },
            tooltip: {
                custom: function({series, seriesIndex}) {
                    let htmlStr = '';
                    let value = Math.floor(series[seriesIndex] * 10) / 10;

                    htmlStr += '<div class="pieChartTootip">';
                    htmlStr += '<span>';
                    htmlStr += labels[seriesIndex] + ": " + value + "%";
                    htmlStr += '</span>';
                    htmlStr += '</div>';

                    return htmlStr;
                }
            }
        }
    };

    return (
        <>
        {
            pitchData.length > 0 ?
            <Chart options={chartData.options} series={chartData.series} type="pie"/> :
            <div className="noDataWrap">No Data</div>
        }
        </>
    )
}

export default PieChartBallType;