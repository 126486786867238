import CircleLoader from "react-spinners/CircleLoader";

function DefaultLoader() {
    const style={
        width: '100%',
        height: '100%',
        position: 'relative'
    }

    const loaderStyle = { 
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    };
    
    return (
        <div style={style}>
            <div style={loaderStyle}>
                <CircleLoader
                    color={'#ff6600'}
                    loading={true}
                    // height={400}
                    // width={100}
                    // radius={10}
                    // margin={20}
                    speedMultiplier={1}
                />
            </div>
        </div>
    )
}

export default DefaultLoader;