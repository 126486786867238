import React, { useEffect, useState, useRef } from 'react';
import axios from "axios";
import _ from 'lodash';
import BallIcon from '../../assets/img/icon/ball_icon.png';
import DefaultLoader from "../../components/loader/DefaultLoader";
import Pagination from '../../components/pagination/Pagination';

function MedicalMain({playerId}) {
    let medicalRef = useRef();
    let [medicalArr, setMedicalArr] = useState([]);
    let [loading, setLoading] = useState(false);

    let [currentPage, setCurrentPage] = useState(1);
    let [totalArticle, setTotalArticle] = useState(0);
    let articlePerPage = 5;

    let colorCode = {
        9301: "medicalCode01",
        9305: "medicalCode02",
        9302: "medicalCode03",
        9303: "medicalCode04",
        9304: "medicalCode05",
        9306: "medicalCode06",
        9307: "medicalCode07",
        9308: "medicalCode08"
    }

    const onOpenLink = (event) => {
        let injuryId = event.currentTarget.id;

        // console.log(injuryId);

        let baseUrl = process.env.REACT_APP_MEDICAL_SITE_URL;
        let url = baseUrl + "/training/injury/detail?tab=injuryInfo&injuryId=" + injuryId;

        window.open(url, "_blank", "noopener, noreferrer");
    }

    useEffect(() => {
        axios({
            url : `${process.env.REACT_APP_MEDICAL_RECORD_URL}/injuryInfo/injuryInfoDetail`,
            params: {
                playerMembersId: playerId
            },
            withCredentials: true
        }).then((result) => {
            let medicalList = result['data']['RECORD'];
            // console.log(medicalList);

            setTotalArticle(medicalList.length);

            let totalPage = Math.ceil(totalArticle / articlePerPage);
            let remainArticle = totalArticle % articlePerPage;

            let start = articlePerPage * (currentPage - 1);
            let end = articlePerPage * currentPage;

            if(totalPage === currentPage && remainArticle !== 0){
                end = start + remainArticle;
            }

            // console.log(start);
            // console.log(end);

            let sortedMedicalList = _.sortBy(medicalList, ['injuryDate']).reverse();

            // console.log(sortedMedicalList);

            let slicedMedicalList = sortedMedicalList.slice(start, end);

            // console.log(slicedMedicalList);

            setMedicalArr(slicedMedicalList);

            setLoading(true);
        })
    },[playerId, articlePerPage, currentPage, totalArticle]);

    if(!loading) {
        return (
            <div className="recordWrap">
                <div id="recordTitle">
                    <img src={BallIcon} alt=""/>
                    메디컬 케이스
                </div>
                <div id="medicalInfoBox">
                    <div style={{height: '200px'}}><DefaultLoader/></div>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="recordWrap" ref={medicalRef}>
                <div id="recordTitle">
                    <img src={BallIcon} alt=""/>
                    메디컬 케이스
                </div>
                <div id="medicalInfoBox">
                    <div className="hiballTable">
                        <table>
                            <colgroup>
                                <col width="8%"/>
                                <col width="15%"/>
                                <col width="15%"/>
                                <col width="*"/>
                                <col width="15%"/>
                                <col width="15%"/>
                                <col width="8%"/>
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>No.</th>
                                    <th>소속</th>
                                    <th>부상일자</th>
                                    <th>진단명</th>
                                    <th>복귀예정일</th>
                                    <th>현재상태</th>
                                    <th>보기</th>
                                </tr>
                            </thead>
                            <tbody>
                            {medicalArr.length > 0 ?
                            medicalArr.map((item, index) => (
                                <tr key={index}>
                                    <td>{(currentPage - 1) * 5 + index + 1}</td>
                                    <td>{item.scoutPlayerTeamName}</td>
                                    <td>{item.injuryDate}</td>
                                    <td>{item.injuryName}</td>
                                    <td>{item.injuryReturnDate}</td>
                                    <td><span className={"medicalStatusIcon " + colorCode[item.injuryNowStateCodeId]}></span>{item.injuryNowStateName}</td>
                                    <td><button onClick={onOpenLink} id={item.injuryId}>보기</button></td>
                                </tr>
                            )) :
                            <tr>
                                <td colSpan="7">No Data</td>
                            </tr>
                            }
                            </tbody>
                        </table>
                    </div>
                    <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalArticle={totalArticle} articlePerPage={articlePerPage} afterFocusRef={medicalRef} />
                </div>
            </div>
        </>
    )
}

export default MedicalMain;