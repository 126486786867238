import React from 'react';

function Pagination(props) {
    const currentPage = props.currentPage;
    const totalArticle = props.totalArticle;
    const articlePerPage = props.articlePerPage;

    const totalPage = Math.ceil(totalArticle / articlePerPage);

    const onChangePageNum = (event) => {
        let id = event.currentTarget.id;
        let splitedId = id.split("_");
        let pageNum = splitedId[1] * 1;

        // console.log(pageNum);

        props.setCurrentPage(pageNum);

        props.afterFocusRef.current.scrollIntoView();
    }

    const rendering = () => {
        const result = [];
        for (let i = 1; i <= totalPage; i++) {
            if(i === currentPage){
                result.push(<span key={i} className="on">{i}</span>);
            } else {
                result.push(<span key={i} id={"pageNum_" + i} onClick={onChangePageNum}>{i}</span>);
            }
        }
        return result;
    };

    return (
        <div id="paginationBox">
            {rendering()}
        </div>
    )
}

export default Pagination;