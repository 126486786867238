import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from "axios";
import _ from 'lodash';
import statFormat from '../../../utils/stat/statFormat';
import { useCookies } from 'react-cookie';
import dateSetting from '../../../utils/dateSetting';
import DefaultLoader from "../../../components/loader/DefaultLoader";
import StatBarGraph from './StatBarGraph';

function PennantRaceRecord({playerId, playerType, leagueType}) {
    let dateObj = dateSetting();
    const thisYear = dateObj['season'];
    const [cookies] = useCookies(['hiball_token']);
    let [loading, setLoading] = useState(false);
    let [seasonRecord, setSeasonRecord] = useState({});
    // let [leagueAvg, setLeagueAvg] = useState({});

    function updateSeasonRecord(key, data){
        setSeasonRecord((prevState) => {
            return {...prevState, [key]: data};
        });
    }

    function jsonKeyLowerCase(object){
        if(Array.isArray(object)){
            object.forEach((item, index) =>{
                object[index] = Object.fromEntries(Object.entries(item).map(([key, value]) => [key.toLowerCase(), value]));
            });
            return object;
        }
        else {
            return Object.fromEntries(Object.entries(object).map(([key, value]) => [key.toLowerCase(), value]));
        }
    }

    useEffect(() => {
        let basicRecordApiParam = {
            recordType: 'digestRecord', recordName: 'selectRankInfo',
            seasons: [thisYear-2, thisYear-1, thisYear].toString(),
            groupingColumn: 'season',
            gameTypeCode: 4201,
            authToken:JSON.stringify(cookies['hiball_token'])
        }

        if(playerType === "batter"){
            basicRecordApiParam['batterIds'] = playerId;
        } else {
            basicRecordApiParam['pitcherIds'] = playerId;
        }

        let basicRecord = axios({
            url : `${process.env.REACT_APP_API_URL}`,
            params: basicRecordApiParam
        });

        // let leagueAvgApiParam = {
        //     gameTypeCode: 4201,
        //     authToken:JSON.stringify(cookies['hiball_token'])
        // };

        // if(playerType === "batter"){
        //     leagueAvgApiParam['recordType'] = 'battingRecord';
        //     leagueAvgApiParam['recordName'] = 'selectBatterSimpleRecordGroupByGameDay';
        // } else {
        //     leagueAvgApiParam['recordType'] = 'pitchingRecord';
        //     leagueAvgApiParam['recordName'] = 'seasonPitcherSimpleRecordGroupByGameDay';
        // }

        // leagueAvgApiParam['seasons'] = (thisYear-2).toString();

        // let leagueAvgBeforeLastYear = axios({
        //     url : `${process.env.REACT_APP_API_URL}`,
        //     params: leagueAvgApiParam
        // });

        // leagueAvgApiParam['seasons'] = (thisYear-1).toString();

        // let leagueAvgLastYear = axios({
        //     url : `${process.env.REACT_APP_API_URL}`,
        //     params: leagueAvgApiParam
        // });

        // leagueAvgApiParam['seasons'] = (thisYear).toString();

        // let leagueAvgThisYear = axios({
        //     url : `${process.env.REACT_APP_API_URL}`,
        //     params: leagueAvgApiParam
        // });
        
        Promise.all([
            basicRecord
            // basicRecord, leagueAvgBeforeLastYear, leagueAvgLastYear, leagueAvgThisYear
        ]).then((result) => {
            let record = result[0]['data']['result'];
            let filteringRecord = _.filter(record, (item) => item.player_type === playerType);
            let groupingSeasonRecord = _.keyBy(filteringRecord, 'season');
            // let avgBeforeLastYear = [];
            // let avgLastYear = [];
            // let avgThisYear = [];

            // if(playerType === "batter"){
            //     avgBeforeLastYear = result[1]['data']['result'][0]['statCacled'];
            //     avgLastYear = result[2]['data']['result'][0]['statCacled'];
            //     avgThisYear = result[3]['data']['result'][0]['statCacled'];
            // } else {
            //     avgBeforeLastYear = result[1]['data']['result'][0]['statCalced'];
            //     avgLastYear = result[2]['data']['result'][0]['statCalced'];
            //     avgThisYear = result[3]['data']['result'][0]['statCalced'];
            // }

            // console.log(groupingSeasonRecord);

            // console.log(avgBeforeLastYear);
            // console.log(avgLastYear);
            // console.log(avgThisYear);

            for(let i = thisYear-2; i <= thisYear; i++){
                if(groupingSeasonRecord[i]){
                    let calcData = statFormat(groupingSeasonRecord[i]);
                    let lowerCaseData = jsonKeyLowerCase(calcData);
                    updateSeasonRecord(i, lowerCaseData);
                }
            }

            // setLeagueAvg({
            //     [thisYear-2]: avgBeforeLastYear,
            //     [thisYear-1]: avgLastYear,
            //     [thisYear]: avgThisYear
            // })

            setLoading(true);
        })
    },[cookies, leagueType, playerType, playerId, thisYear]);

    if(!loading) {
        return <div style={{height: '135px'}}><DefaultLoader/></div>
    }

    return (
        <>
        {
            playerType === "batter" &&
            <div id="recentRecordItemBox">
                <Row>
                    <Col md="auto" className="recentRecordRowTitle">
                        <div></div>
                        <div>{thisYear-2}</div>
                        <div>{thisYear-1}</div>
                        <div>{thisYear}</div>
                    </Col>
                    <Col className="recentRecordItem">
                        <div className="recentRecordItemTitle">
                            타율
                        </div>
                        {
                        seasonRecord[thisYear-2] ?
                        <StatBarGraph record={seasonRecord} year={thisYear-2} stat={"avg"} playerType={playerType}/> :
                        <div className="recentRecordItemBar">
                            <div className="value">No Data</div>
                        </div>
                        }
                        {
                        seasonRecord[thisYear-1] ?
                        <StatBarGraph record={seasonRecord} year={thisYear-1} stat={"avg"} playerType={playerType}/> :
                        <div className="recentRecordItemBar">
                            <div className="value">No Data</div>
                        </div>
                        }
                        {
                        seasonRecord[thisYear] ?
                        <StatBarGraph record={seasonRecord} year={thisYear} stat={"avg"} playerType={playerType}/> :
                        <div className="recentRecordItemBar">
                            <div className="value">No Data</div>
                        </div>
                        }
                    </Col>
                    <Col className="recentRecordItem">
                        <div className="recentRecordItemTitle">
                            OPS
                        </div>
                        {
                        seasonRecord[thisYear-2] ?
                        <StatBarGraph record={seasonRecord} year={thisYear-2} stat={"ops"} playerType={playerType}/> :
                        <div className="recentRecordItemBar">
                            <div className="value">No Data</div>
                        </div>
                        }
                        {
                        seasonRecord[thisYear-1] ?
                        <StatBarGraph record={seasonRecord} year={thisYear-1} stat={"ops"} playerType={playerType}/> :
                        <div className="recentRecordItemBar">
                            <div className="value">No Data</div>
                        </div>
                        }
                        {
                        seasonRecord[thisYear] ?
                        <StatBarGraph record={seasonRecord} year={thisYear} stat={"ops"} playerType={playerType}/> :
                        <div className="recentRecordItemBar">
                            <div className="value">No Data</div>
                        </div>
                        }
                    </Col>
                    <Col className="recentRecordItem">
                        <div className="recentRecordItemTitle">
                            인존 컨택트%
                        </div>
                        {
                        seasonRecord[thisYear-2] ?
                        <StatBarGraph record={seasonRecord} year={thisYear-2} stat={"in_zone_contact"} playerType={playerType}/> :
                        <div className="recentRecordItemBar">
                            <div className="value">No Data</div>
                        </div>
                        }
                        {
                        seasonRecord[thisYear-1] ?
                        <StatBarGraph record={seasonRecord} year={thisYear-1} stat={"in_zone_contact"} playerType={playerType}/> :
                        <div className="recentRecordItemBar">
                            <div className="value">No Data</div>
                        </div>
                        }
                        {
                        seasonRecord[thisYear] ?
                        <StatBarGraph record={seasonRecord} year={thisYear} stat={"in_zone_contact"} playerType={playerType}/> :
                        <div className="recentRecordItemBar">
                            <div className="value">No Data</div>
                        </div>
                        }
                    </Col>
                    <Col className="recentRecordItem">
                        <div className="recentRecordItemTitle">
                            삼진%
                        </div>
                        {
                        seasonRecord[thisYear-2] ?
                        <StatBarGraph record={seasonRecord} year={thisYear-2} stat={"so"} playerType={playerType}/> :
                        <div className="recentRecordItemBar">
                            <div className="value">No Data</div>
                        </div>
                        }
                        {
                        seasonRecord[thisYear-1] ?
                        <StatBarGraph record={seasonRecord} year={thisYear-1} stat={"so"} playerType={playerType}/> :
                        <div className="recentRecordItemBar">
                            <div className="value">No Data</div>
                        </div>
                        }
                        {
                        seasonRecord[thisYear] ?
                        <StatBarGraph record={seasonRecord} year={thisYear} stat={"so"} playerType={playerType}/> :
                        <div className="recentRecordItemBar">
                            <div className="value">No Data</div>
                        </div>
                        }
                    </Col>
                    <Col className="recentRecordItem">
                        <div className="recentRecordItemTitle">
                            볼넷%
                        </div>
                        {
                        seasonRecord[thisYear-2] ?
                        <StatBarGraph record={seasonRecord} year={thisYear-2} stat={"bb"} playerType={playerType}/> :
                        <div className="recentRecordItemBar">
                            <div className="value">No Data</div>
                        </div>
                        }
                        {
                        seasonRecord[thisYear-1] ?
                        <StatBarGraph record={seasonRecord} year={thisYear-1} stat={"bb"} playerType={playerType}/> :
                        <div className="recentRecordItemBar">
                            <div className="value">No Data</div>
                        </div>
                        }
                        {
                        seasonRecord[thisYear] ?
                        <StatBarGraph record={seasonRecord} year={thisYear} stat={"bb"} playerType={playerType}/> :
                        <div className="recentRecordItemBar">
                            <div className="value">No Data</div>
                        </div>
                        }
                    </Col>
                </Row>
            </div>
        }
        {
            playerType === "pitcher" &&
            <div id="recentRecordItemBox">
                <Row>
                    <Col md="auto" className="recentRecordRowTitle">
                        <div></div>
                        <div>{thisYear-2}</div>
                        <div>{thisYear-1}</div>
                        <div>{thisYear}</div>
                    </Col>
                    <Col className="recentRecordItem">
                        <div className="recentRecordItemTitle">
                            피안타율
                        </div>
                        {
                        seasonRecord[thisYear-2] ?
                        <StatBarGraph record={seasonRecord} year={thisYear-2} stat={"avg"} playerType={playerType}/> :
                        <div className="recentRecordItemBar">
                            <div className="value">No Data</div>
                        </div>
                        }
                        {
                        seasonRecord[thisYear-1] ?
                        <StatBarGraph record={seasonRecord} year={thisYear-1} stat={"avg"} playerType={playerType}/> :
                        <div className="recentRecordItemBar">
                            <div className="value">No Data</div>
                        </div>
                        }
                        {
                        seasonRecord[thisYear] ?
                        <StatBarGraph record={seasonRecord} year={thisYear} stat={"avg"} playerType={playerType}/> :
                        <div className="recentRecordItemBar">
                            <div className="value">No Data</div>
                        </div>
                        }
                    </Col>
                    <Col className="recentRecordItem">
                        <div className="recentRecordItemTitle">
                            OPS
                        </div>
                        {
                        seasonRecord[thisYear-2] ?
                        <StatBarGraph record={seasonRecord} year={thisYear-2} stat={"ops"} playerType={playerType}/> :
                        <div className="recentRecordItemBar">
                            <div className="value">No Data</div>
                        </div>
                        }
                        {
                        seasonRecord[thisYear-1] ?
                        <StatBarGraph record={seasonRecord} year={thisYear-1} stat={"ops"} playerType={playerType}/> :
                        <div className="recentRecordItemBar">
                            <div className="value">No Data</div>
                        </div>
                        }
                        {
                        seasonRecord[thisYear] ?
                        <StatBarGraph record={seasonRecord} year={thisYear} stat={"ops"} playerType={playerType}/> :
                        <div className="recentRecordItemBar">
                            <div className="value">No Data</div>
                        </div>
                        }
                    </Col>
                    <Col className="recentRecordItem">
                        <div className="recentRecordItemTitle">
                            평균 타구속도
                        </div>
                        {
                        seasonRecord[thisYear-2] ?
                        <StatBarGraph record={seasonRecord} year={thisYear-2} stat={"exit_speed_avg"} playerType={playerType}/> :
                        <div className="recentRecordItemBar">
                            <div className="value">No Data</div>
                        </div>
                        }
                        {
                        seasonRecord[thisYear-1] ?
                        <StatBarGraph record={seasonRecord} year={thisYear-1} stat={"exit_speed_avg"} playerType={playerType}/> :
                        <div className="recentRecordItemBar">
                            <div className="value">No Data</div>
                        </div>
                        }
                        {
                        seasonRecord[thisYear] ?
                        <StatBarGraph record={seasonRecord} year={thisYear} stat={"exit_speed_avg"} playerType={playerType}/> :
                        <div className="recentRecordItemBar">
                            <div className="value">No Data</div>
                        </div>
                        }
                    </Col>
                    <Col className="recentRecordItem">
                        <div className="recentRecordItemTitle">
                            K%
                        </div>
                        {
                        seasonRecord[thisYear-2] ?
                        <StatBarGraph record={seasonRecord} year={thisYear-2} stat={"so"} playerType={playerType}/> :
                        <div className="recentRecordItemBar">
                            <div className="value">No Data</div>
                        </div>
                        }
                        {
                        seasonRecord[thisYear-1] ?
                        <StatBarGraph record={seasonRecord} year={thisYear-1} stat={"so"} playerType={playerType}/> :
                        <div className="recentRecordItemBar">
                            <div className="value">No Data</div>
                        </div>
                        }
                        {
                        seasonRecord[thisYear] ?
                        <StatBarGraph record={seasonRecord} year={thisYear} stat={"so"} playerType={playerType}/> :
                        <div className="recentRecordItemBar">
                            <div className="value">No Data</div>
                        </div>
                        }
                    </Col>
                    <Col className="recentRecordItem">
                        <div className="recentRecordItemTitle">
                            BB%
                        </div>
                        {
                        seasonRecord[thisYear-2] ?
                        <StatBarGraph record={seasonRecord} year={thisYear-2} stat={"bb"} playerType={playerType}/> :
                        <div className="recentRecordItemBar">
                            <div className="value">No Data</div>
                        </div>
                        }
                        {
                        seasonRecord[thisYear-1] ?
                        <StatBarGraph record={seasonRecord} year={thisYear-1} stat={"bb"} playerType={playerType}/> :
                        <div className="recentRecordItemBar">
                            <div className="value">No Data</div>
                        </div>
                        }
                        {
                        seasonRecord[thisYear] ?
                        <StatBarGraph record={seasonRecord} year={thisYear} stat={"bb"} playerType={playerType}/> :
                        <div className="recentRecordItemBar">
                            <div className="value">No Data</div>
                        </div>
                        }
                    </Col>
                </Row>
            </div>
        }
        </>
    )
}

export default PennantRaceRecord;