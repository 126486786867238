import React from 'react';
import NullPlayer from '../../../assets/img/player/null.png';

function ProfileCard({profile}) {
    function onErrorHandler(event) {
        event.target.src = NullPlayer;
    }

    function handCheck(hand) {
        if(hand ==='R') return '우';
        else return '좌';
    }

    function langCheck(str){
        if(str === "SSG" || str === "LG" || str === "kt" || str === "KT" || str === "KIA" || str === "NC"){
            return false;
        }

        let pattern = /[가-힣]/;
        return !pattern.test(str.trim().slice(0, 1));
    }

    function retireCheck(team){
        if(!Number.isNaN(team)){
            team += "";
        }

        if(team === "99901"){
            return "해외이적";
        } else if(team === "99902") {
            return "입대";
        } else if(team === "99990") {
            return "방출";
        } else if(team === "99999") {
            return "은퇴";
        } else {
            return "";
        }
    }

    return (
        <>
            <div className={"profileImageBox team" + profile['teamId']}>
                <div className="profileImage">
                <img src={`https://image.hanwha.kbostat.net/profileImage/${profile['playerId']}.jpg`}
                    alt="#" onError={onErrorHandler} />
                </div>
                <div className="playerNameBox">
                    {
                    profile['playerName'] &&
                    (
                    langCheck(profile['playerName']) ?
                    <div className="playerName englishName">
                        <div>{profile['playerName'].trim().split(" ")[0]}</div>
                        {
                        profile['playerName'].split(" ")[1] &&
                        <div>{profile['playerName'].trim().split(" ")[1]}</div>
                        }
                    </div> :
                    <div className="playerName">
                        <div>{profile['playerName'].trim()}</div>
                    </div>
                    )
                    }
                    {
                    profile['teamId'] &&
                    (
                    retireCheck(profile['teamId']) !== "" &&
                    <div className="playerRetireReason">
                        ({retireCheck(profile['teamId'])})
                    </div>
                    )
                    }
                    <div className="playerPitchingType">{handCheck(profile['pitchingHand'])}투{handCheck(profile['battingHand'])}타</div>
                    {
                    profile['teamName'] &&
                    (
                    langCheck(profile['teamName']) ?
                    <div className="playerBacknumber englishName">
                        <div>{profile['teamName'].trim().split(" ")[0]}</div>
                        {
                        profile['teamName'].split(" ")[1] &&
                        <div>{profile['teamName'].trim().split(" ")[1]} {profile['backNumber'] ? " No." + profile['backNumber'] : ""}</div>
                        }
                    </div> :
                    <div className="playerBacknumber">
                        <div>{profile['teamName']} {profile['backNumber'] ? " No." + profile['backNumber'] : ""}</div>
                    </div>
                    )
                    }
                </div>
            </div>
        </>
    )
}

export default ProfileCard;