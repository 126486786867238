import React from 'react';
import Col from 'react-bootstrap/Col';
import ProfileCard from './sub/ProfileCard';
import ProfileRecordL from './sub/ProfileRecordL';
import ProfileRecordR from './sub/ProfileRecordR';
import DefaultLoader from "../../components/loader/DefaultLoader";

function ProfileMain({profile}) {

    const style = {
        height: '450px',
        display: 'flex',
        flexDirection: 'column'
    }

    return (
        <>
            <div id="playerProfile">
            {
                profile['teamId'] ?
                <ProfileCard profile={profile}/> :
                <div style={{...style, ...{height:'146px'}}}>
                    <DefaultLoader/>
                </div>
            }
            </div>
            <div id="profileRecordBox">
                <Col md={6}>
                {
                    profile['teamId'] ?
                    <ProfileRecordL profile={profile}/> :
                    <div style={{...style, ...{height:'122px'}}}>
                        <DefaultLoader/>
                    </div>
                }
                </Col>
                <Col md={6}>
                {
                    profile['teamId'] ?
                    <ProfileRecordR profile={profile}/> :
                    <div style={{...style, ...{height:'122px'}}}>
                        <DefaultLoader/>
                    </div>
                }
                </Col>
            </div>
        </>
    )
}

export default ProfileMain;