import React from 'react';

function ProfileRecordL({profile}) {
    const firstJoinYearHandler = (event) => {
        let value = event.currentTarget.innerHTML;

        // console.log(value);

        window.location.href = "/playerlist/join/" + value.trim();
    }

    return (
        <>
            <div className="dataTable">
                <table>
                    <colgroup>
                        <col width="20%"/>
                        <col width="30%"/>
                        <col width="20%"/>
                        <col width="30%"/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>이름(영문)</th>
                            <td>{profile['playerName']}<br/>{profile['englishName'] && "(" + profile['englishName'] + ")"}</td>
                            <th>주포지션</th>
                            <td>{profile['position']}</td>
                        </tr>
                        <tr>
                            <th>생년월일</th>
                            <td>{profile['birthdate']}</td>
                            <th>등번호</th>
                            <td>{profile['backNumber']}</td>
                        </tr>
                        <tr>
                            <th>소속</th>
                            <td>{profile['teamName']}</td>
                            <th>입단년도</th>
                            <td>
                                <span className="playerListLink" onClick={firstJoinYearHandler}>
                                    {profile['firstJoinYear']}
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default ProfileRecordL;