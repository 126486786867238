import React, { useEffect, useRef } from 'react';
import SearchBtn from '../../../assets/img/icon/searchBtn.png';

function SearchFormForSummary({playerArr, formSubmitHandler, inputRef}) {
    let listRef = useRef();
    let pitcherCodeArr = [5201, 5211, 9801];

    const playerProfileHandler = (event) => {
        let id = event.currentTarget.id;

        let idSplit = id.split('_');
        let positionTypeCode = idSplit[0];
        let playerId = idSplit[1];

        let playerType = (pitcherCodeArr.indexOf(positionTypeCode*1) > -1) ? 'pitcher' : 'batter';
        window.location.href = "/"+playerType+"/"+playerId;
    }

    function handCheck(hand) {
        if(hand ==='R') return '우';
        else return '좌';
    }

    function positionCheck(position) {
        if(pitcherCodeArr.indexOf(position*1) > -1) return '투수';
        else return '타자';
    }

    useEffect(() => {
        document.addEventListener('click', (event) => {
            if(event.target.id !== 'searchInput') {
                listRef.current.style.display = 'none';
            }
        });

        if(playerArr.length !== 0){
            if(playerArr.length === 1){
                let profile = playerArr[0];

                let playerId = profile.membersId;
                let positionTypeCode = profile.positionTypeCode;

                let playerType = (positionTypeCode*1 === 5201 || positionTypeCode*1 === 5211 || positionTypeCode*1 === 9801) ? 'pitcher' : 'batter';
                window.location.href = "/"+playerType+"/"+playerId;
            } else {
                listRef.current.style.display = 'inline-block';
            }
        }
    }, [listRef, playerArr]);

    return (
        <>
            <div id="searchBarSummary">
                <form id="searchFormSummary" onSubmit={formSubmitHandler}>
                    <input type="text" placeholder="선수명을 입력하세요." ref={inputRef} />
                    <div className="searchIconWrap">
                        <button id="searchPlayerBtn" type="submit" onSubmit={formSubmitHandler}>
                            <img src={SearchBtn} alt="#"/>
                        </button>
                    </div>
                </form>
                <div className="search_player_list_container" style={{display: 'none'}} ref={listRef}>
                    <div className="search_player_list_conatiner_title">
                        검색 선수
                    </div>
                    <div className="search_list_area" id="search_list_area">
                    {
                    playerArr.map((profile, index) => (
                        <div key={index} className="search_player_item"
                        id={profile['positionTypeCode'] + "_" + (profile['membersId'] !== 0 ? profile['membersId'] : "s" + profile['scoutPlayerMembersId'])} onClick={playerProfileHandler}>
                            <div className="title">{profile['membersName']} No.{profile['backnumber']}</div>
                            <div className="content">
                                {profile['teamName']} {positionCheck(profile['positionTypeCode'])} {handCheck(profile['pitchingHand'])}투{handCheck(profile['battingHand'])}타
                            </div>
                        </div>
                    ))
                    }
                    </div>
                </div>
            </div>
        </>
    )
}

export default SearchFormForSummary;