import React, { useRef } from 'react';
import _ from 'lodash';
import SearchList from './SearchList';
import Select from 'react-select';

function SearchForm({playerArr, proTeamArr, amateurTeamArr, formSubmitHandler, inputRef, 
    selectedCategory, setSelectedCategory, selectedDepartment, setSelectedDepartment, setSelectedTeam}){

    let departmentRef = useRef();
    let teamRef = useRef();

    let defaultValue = {value: 'all', label: '전체'};

    let categoryArr = [
        {value: 'all', label: '전체'},
        {value: 'pro', label: '프로'},
        {value: 'amateur', label: '아마추어'}
    ];

    let proDepartmentArr = [
        {value: 'pro', label: '프로팀'}
    ];

    let amateurDepartmentArr = [
        {value: 9701, label: '대학교'},
        {value: 9702, label: '고등학교'},
        {value: 9703, label: '해외팀'},
        {value: 9704, label: '훈련관찰'},
        {value: 9705, label: '연습경기'},
        {value: 9709, label: '기타'}
    ];

    let departmentArr = [];

    if(selectedCategory === "all" || selectedCategory === "pro"){
        _.forEach(proDepartmentArr, (item) => {
            departmentArr.push(item);
        });
    }
    if(selectedCategory === "all" || selectedCategory === "amateur"){
        _.forEach(amateurDepartmentArr, (item) => {
            departmentArr.push(item);
        });
    }

    departmentArr.unshift({value: 'all', label: '전체'});

    // console.log(departmentArr);

    let teamArr = [];

    if(selectedCategory !== "amateur" && (selectedDepartment === "all" || selectedDepartment === "pro")){
        _.forEach(proTeamArr, (item) => {
            teamArr.push({value: item.teamId, label: item.teamName});
        });
    }
    if(selectedCategory !== "pro" && (selectedDepartment === "all" || selectedDepartment !== "pro")){
        _.forEach(amateurTeamArr, (item) => {
            teamArr.push({value: item.scoutTeamId, label: item.scoutTeamName});
        });
    }

    teamArr.unshift({value: 'all', label: '전체'});

    // console.log(teamArr);

    const handleSelectCategory = (e) => {
        setSelectedCategory(e.value);
        if(e.value === "pro"){
            departmentRef.current.setValue({value: 'pro', label: '프로팀'});
        } else {
            departmentRef.current.setValue(defaultValue);
        }
    };

    const handleSelectDepartment = (e) => {
        setSelectedDepartment(e.value);
        teamRef.current.setValue(defaultValue);
    };

    const handleSelectTeam = (e) => {
        setSelectedTeam(e.value);
    };

    return (
        <>
            <div id="searchBarWrap">
                <form id="searchForm" onSubmit={formSubmitHandler}>
                    <div className="filterBox">
                        <label htmlFor="selectCategory">구분</label>
                        <Select id="selectCategory" options={categoryArr} components={{IndicatorSeparator: () => null}} defaultValue={defaultValue} onChange={handleSelectCategory}/>
                    </div>
                    <div className="filterBox">
                        <label htmlFor="selectDepartment">소속</label>
                        <Select id="selectDepartment" options={departmentArr} components={{IndicatorSeparator: () => null}} defaultValue={defaultValue} onChange={handleSelectDepartment} ref={departmentRef}/>
                    </div>
                    <div className="filterBox">
                        <label htmlFor="selectTeam">팀</label>
                        <Select id="selectTeam" options={teamArr} components={{IndicatorSeparator: () => null}} defaultValue={defaultValue} onChange={handleSelectTeam} ref={teamRef}/>
                    </div>
                    <input id="searchBar" type="text" placeholder="선수명을 입력하세요." ref={inputRef} autoFocus />
                    <button id="searchBtn" type="submit" onSubmit={formSubmitHandler}>
                        검색
                    </button>
                </form>
                <div id="playerCardWrap" className={playerArr.length > 4 ? "overflowItem" : ""}>
                { 
                    playerArr.length !== 0 &&
                    <SearchList data={playerArr}/>
                }
                </div>
            </div>
        </>
    )
}

export default SearchForm;