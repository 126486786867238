import React, { useEffect, useState, useRef } from 'react';
import axios from "axios";
import _ from 'lodash';
import BallIcon from '../../assets/img/icon/ball_icon.png';
import DefaultLoader from "../../components/loader/DefaultLoader";
import Pagination from '../../components/pagination/Pagination';

function ScoutingMain({membersId}) {
    let scoutingRef = useRef();
    let [scoutingArr, setScoutingArr] = useState([]);
    let [loading, setLoading] = useState(false);

    let [currentPage, setCurrentPage] = useState(1);
    let [totalArticle, setTotalArticle] = useState(0);
    let articlePerPage = 5;

    let [reportCntObj, setReportCntObj] = useState({
        player: 0,
        game: 0
    });

    let [currentReportTab, setCurrentReportTab] = useState("9201");

    const onSwitchReportType = (event) => {
        let id = event.currentTarget.id;

        let idSplit = id.split('_');
        let reportTypeStr = "9201";
        if(idSplit[1] === "game"){
            reportTypeStr = "9202";
        }

        setCurrentPage(1);
        setCurrentReportTab(reportTypeStr);
    }

    function removeTags(str){
        let removeTagsStr = str.toString().replace(/<[^>]*>?/g, " ");
        return removeTagsStr.replace("&nbsp;", " ");
    }

    const onOpenLink = (event) => {
        let targetId = event.currentTarget.id;
        let splitedTargetId = targetId.split("_");

        let playerId = splitedTargetId[0];
        let reportingId = splitedTargetId[1];
        let reportingTypeCodeId = splitedTargetId[2];
        let reportingPosition = splitedTargetId[3];
        let subTab = "pitcherReport";

        // console.log(playerId);
        // console.log(reportingId);
        // console.log(reportingTypeCodeId);
        // console.log(reportingPosition);

        if(reportingTypeCodeId === "9201"){
            subTab = reportingPosition + "Report";
        } else {
            subTab = reportingPosition + "Game";
        }

        let baseUrl = process.env.REACT_APP_SCOUTING_SITE_URL;
        let url = baseUrl + "/scout/report/form?tab=playerReport&id=" + playerId + "&reportingId=" + reportingId + "&subTab=" + subTab;

        window.open(url, "_blank", "noopener, noreferrer");
    }

    useEffect(() => {
        let apiData = {};

        if(membersId.playerMembersId === 0){
            apiData['scoutPlayerMembersId'] = membersId.scoutPlayerMembersId;
        } else {
            apiData['playerMembersId'] = membersId.playerMembersId;
        }

        axios.post(
            `${process.env.REACT_APP_SCOUTING_RECORD_URL}/scoutReportingInfo/reportingInfo`,
            null,
            {
                params: apiData,
                withCredentials: true
            }
        ).then((result) => {
            let scoutingList = result['data']['RECORD'];

            let groupingScoutingList = _.groupBy(scoutingList, (item) => {
                return item.reportingTypeCodeId;
            });

            // console.log(groupingScoutingList);

            let newReportCntObj = {};

            newReportCntObj['player'] = groupingScoutingList['9201'] ? groupingScoutingList['9201'].length : 0;
            newReportCntObj['game'] = groupingScoutingList['9202'] ? groupingScoutingList['9202'].length : 0;

            setReportCntObj(newReportCntObj);

            let filteringScoutingList = _.filter(scoutingList, (item) => item.reportingTypeCodeId === currentReportTab);

            _.forEach(filteringScoutingList, function(item){
                if(item.reportingComment){
                    item.reportingComment = removeTags(item.reportingComment);
                }
                // console.log(item.reportingComment);
            })

            // console.log(scoutingList);

            setTotalArticle(filteringScoutingList.length);

            let totalPage = Math.ceil(totalArticle / articlePerPage);
            let remainArticle = totalArticle % articlePerPage;

            let start = articlePerPage * (currentPage - 1);
            let end = articlePerPage * currentPage;

            if(totalPage === currentPage && remainArticle !== 0){
                end = start + remainArticle;
            }

            // console.log(start);
            // console.log(end);

            let sortedScoutingList = _.sortBy(filteringScoutingList, ['reportingDate']).reverse();

            // console.log(sortedScoutingList);

            let slicedScoutingList = sortedScoutingList.slice(start, end);

            // console.log(slicedScoutingList);

            setScoutingArr(slicedScoutingList);

            setLoading(true);
        })
    },[membersId, articlePerPage, currentPage, totalArticle, currentReportTab]);

    if(!loading) {
        return (
            <div className="recordWrap">
                <div id="recordTitle">
                    <img src={BallIcon} alt=""/>
                    스카우팅 정보
                </div>
                <div id="scoutingInfoBox">
                    <div style={{height: '200px'}}><DefaultLoader/></div>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="recordWrap" ref={scoutingRef}>
                <div id="recordTitle">
                    <img src={BallIcon} alt=""/>
                    스카우팅 정보
                </div>
                <div id="tabMenu">
                    <div id="report_player" className={currentReportTab === "9201" ? "on" : ""} onClick={onSwitchReportType} style={{width: '150px'}}>선수보고서({reportCntObj['player']})</div>
                    <div id="report_game" className={currentReportTab === "9202" ? "on" : ""} onClick={onSwitchReportType} style={{width: '170px'}}>경기관찰보고서({reportCntObj['game']})</div>
                </div>
                <div id="scoutingInfoBox">
                    <div className="hiballTable">
                        <table>
                            <colgroup>
                                <col width="8%"/>
                                <col width="15%"/>
                                <col width="15%"/>
                                <col width="*"/>
                                <col width="15%"/>
                                <col width="8%"/>
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>No.</th>
                                    <th>소속</th>
                                    <th>작성일</th>
                                    <th>내용</th>
                                    <th>리그</th>
                                    <th>보기</th>
                                </tr>
                            </thead>
                            <tbody>
                            {scoutingArr.length > 0 ?
                            scoutingArr.map((item, index) => (
                                <tr key={index}>
                                    <td>{(currentPage - 1) * 5 + index + 1}</td>
                                    <td>{item.scoutPlayerTeamName}</td>
                                    <td>{item.reportingDate}</td>
                                    {
                                    currentReportTab === "9201" &&
                                    <td style={{textAlign: 'left', fontSize: '14px'}} dangerouslySetInnerHTML={{__html: (item.reportingContentJson ? (JSON.parse(item.reportingContentJson).totalComment ? JSON.parse(item.reportingContentJson).totalComment.replace(/\n/g, '<br/>') : "") : "")}}></td>
                                    }
                                    {
                                    currentReportTab === "9202" &&
                                    <td style={{textAlign: 'left', fontSize: '14px'}} dangerouslySetInnerHTML={{__html: (item.reportingComment ? item.reportingComment.replace(/\n/g, '<br/>') : "")}}></td>
                                    }
                                    <td>{item.leagueName}</td>
                                    <td><button onClick={onOpenLink} id={item.scoutPlayerMembersId + "_" + item.reportingId + "_" +
                                        item.reportingTypeCodeId  + "_" + item.reportingPosition}>보기</button></td>
                                </tr>
                            )) :
                            <tr>
                                <td colSpan="6">No Data</td>
                            </tr>
                            }
                            </tbody>
                        </table>
                    </div>
                    <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalArticle={totalArticle} articlePerPage={articlePerPage} afterFocusRef={scoutingRef} />
                </div>
            </div>
        </>
    )
}

export default ScoutingMain;